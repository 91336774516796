.iq-profile-avatar.status-primary:before { background-color: #6a75ca; }
.iq-profile-avatar.status-blue:before { background-color: #468bd8; }
.iq-profile-avatar.status-info:before { background-color: #17a2b8; }
.iq-profile-avatar.status-danger:before { background-color: #ea5455; }
.bg-blue { background-color: #468bd8 !important; }
.dash-hover-gradient { background-color: #fff; min-height: 200px; }
.dash-hover-gradient:hover { cursor: pointer; background: var(--iq-primary); color: #fff; }
.dash-hover-scale { min-height: 200px; transition: all 200ms ease-in-out; }
.dash-hover-scale:hover { cursor: pointer; transform: scale(1.1); transition: all 200ms ease-in-out; }
.dash-hover-gradient:hover .iq-card-body *, .dash-hover-gradient:hover .iq-card-header i { color: #fff; }


.transformY-min-15 { transform: translateY(-15px); }
.transformY-min-45 { transform: translateY(-45px); }
.text-gray { color: #aaa; }
.top-10{top: 10% !important;}
.top-20{top: 20% !important;}
.top-30{top: 30% !important;}
.top-40{top: 40% !important;}
.top-50{top: 50% !important;}
.top-60{top: 60% !important;}
.top-70{top: 70% !important;}
.top-80{top: 80% !important;}
.top-90{top: 90% !important;}
.top-100{top: 100% !important;}
.top-150{top: 150% !important;}
.top-200{top: 200% !important;}
.font-size-22 { font-size: 22px; }
.font-size-24 { font-size: 24px; }
.font-size-26 { font-size: 26px; }
.font-size-28 { font-size: 28px; }
.font-size-30 { font-size: 30px; }
.font-size-32 { font-size: 32px; }
.font-size-34 { font-size: 34px; }
.font-size-36 { font-size: 36px; }
.font-size-38 { font-size: 38px; }
.font-size-40 { font-size: 40px; }
.height-25 { height: 25px !important; }
.height-50 { height: 50px !important; }
.height-75 { height: 75px !important; }
.height-100 { height: 100px !important; }
.height-125 { height: 125px !important; }
.height-150 { height: 150px !important; }
.height-300 { height: 300px !important; }
.height-500 { height: 500px !important; }
.chartjs-pie-chart { display: inline-block; vertical-align: middle; width: 100%; }
.chart-legend { margin: 0; padding: 0; }
.chart-legend { display: inline-block; vertical-align: middle; width: 80%; font-size: 14px; }
.chart-legend ul { padding: 0px; }
.chart-legend li { line-height: 1.3em; list-style: none; margin: 0 0 .3em 0; min-height: 1.3em; }
.chart-legend li span { border-radius: .3em; display: inline-block; height: 1.3em; left: 0; margin-right: .5em; vertical-align: middle; width: 1.3em; }
.apexcharts-gridlines-horizontal { display: none !important; }

/*  Css for the Dashboard 2 */
.iq-border-radius-5 { border-radius: 5px; }
.iq-border-radius-10 { border-radius: 10px; }
.iq-border-radius-15 { border-radius: 15px; }
.iq-border-radius-20 { border-radius: 20px; }
.iq-border-r-5 { border-right: 5px solid; }
.iq-border-l-5 { border-left: 5px solid; }
.iq-shadow { box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15) !important; }
.iq-profile-card { overflow: visible; }
.left-section { }
.right-section { min-height: 80vh; border-radius: 50px; }
.iq-icon-shape { padding: 12px; text-align: center; display: inline-flex; align-items: center; justify-content: center; width: 3rem; height: 3rem; }

/* Project 7 Dashboard */
.kanban-drag, .kanban-item { padding: 5px !important; }
.kanban-drag { height: 600px; overflow: hidden; overflow-y: scroll; }
.gantt-container { height: 350px; }
.bg-banner-image { background-size: 100% 100% !important; background-repeat: no-repeat !important; height: 250px; }
.banner-bg-color-primary { width: 100%; height: 100%; background: rgba(106, 117, 202, 0.8); border-radius: inherit; }
.iq-mt--50 { margin-top: -50px; }
.full-map { height: 270px; z-index: 0; }
.track { display: -webkit-inline-box; display: -moz-inline-box; overflow-x: scroll; }
::-webkit-scrollbar { width: 8px; height: 8px; border-radius: 20px; }

/* Track */
::-webkit-scrollbar-track { background: #f1f1f1; border-radius: 20px; }

/* Handle */
::-webkit-scrollbar-thumb { background: #888; border-radius: 20px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover { background: #555; border-radius: 20px; }
.dash-tracking-icon { height: 80px; }
.transformX-min-1 { transform: scaleX(-1); }
.iq-page-primary>li>a, .iq-page-primary>li>span{
    background-color: var(--iq-primary);
    color: #fff;
}
.iq-page-primary>.page-item.active .page-link{
    border-color: var(--iq-primary-dark);
    background-color: var(--iq-primary-dark);
}
.iq-page-primary>.page-item.disabled .page-link{
    background-color: var(--iq-primary);
    color: #fff;
}

.iq-page-success>li>a, .iq-page-success>li>span{
    background-color: #00ca00;
    color: #fff;
}
.iq-page-success>.page-item.active .page-link{
    border-color: #009500;
    background-color: #009500;
}
.iq-page-success>.page-item.disabled .page-link{
    background-color: #00ca00;
    color: #fff;
}

.iq-page-info>li>a, .iq-page-info>li>span{
    background-color: #00d0ff;
    color: #fff;
}
.iq-page-info>.page-item.active .page-link{
    border-color: #008fb5;
    background-color: #008fb5;
}
.iq-page-info>.page-item.disabled .page-link{
    background-color: #00d0ff;
    color: #fff;
}

.iq-email-list .iq-email-ui li a{
    padding: .5rem 1rem !important;
}

.custom-checkbox.custom-checkbox-color input[color="primary"]~label::before, .custom-checkbox.custom-checkbox-color-check input:checked[color="primary"]~label::before, .custom-radio.custom-radio-color input[color="primary"]~label::before, .custom-radio.custom-radio-color-checked  input:checked[color="primary"]~label::before, .custom-switch.custom-switch-color input:checked[color="primary"]~label::before { background-color: var(--iq-primary) !important; border-color: var(--iq-primary) !important; }
.custom-checkbox.custom-checkbox-color input[color="success"]~label::before, .custom-checkbox.custom-checkbox-color-check input:checked[color="success"]~label::before, .custom-radio.custom-radio-color input[color="success"]~label::before, .custom-radio.custom-radio-color-checked  input:checked[color="success"]~label::before, .custom-switch.custom-switch-color input:checked[color="success"]~label::before { background-color: #00ca00 !important; border-color: #00ca00 !important; }
.custom-checkbox.custom-checkbox-color input[color="danger"]~label::before, .custom-checkbox.custom-checkbox-color-check input:checked[color="danger"]~label::before, .custom-radio.custom-radio-color input[color="danger"]~label::before, .custom-radio.custom-radio-color-checked  input:checked[color="danger"]~label::before, .custom-switch.custom-switch-color input:checked[color="danger"]~label::before { background-color: #e64141 !important; border-color: #e64141 !important; }
.custom-checkbox.custom-checkbox-color input[color="warning"]~label::before, .custom-checkbox.custom-checkbox-color-check input:checked[color="warning"]~label::before, .custom-radio.custom-radio-color input[color="warning"]~label::before, .custom-radio.custom-radio-color-checked  input:checked[color="warning"]~label::before, .custom-switch.custom-switch-color input:checked[color="warning"]~label::before { background-color: #ffd400 !important; border-color: #ffd400 !important; }
.custom-checkbox.custom-checkbox-color input[color="dark"]~label::before, .custom-checkbox.custom-checkbox-color-check input:checked[color="dark"]~label::before, .custom-radio.custom-radio-color input[color="dark"]~label::before, .custom-radio.custom-radio-color-checked  input:checked[color="dark"]~label::before, .custom-switch.custom-switch-color input:checked[color="dark"]~label::before { background-color: #374948 !important; border-color: #374948 !important; }
.custom-checkbox.custom-checkbox-color input[color="info"]~label::before, .custom-checkbox.custom-checkbox-color-check input:checked[color="info"]~label::before, .custom-radio.custom-radio-color input[color="info"]~label::before, .custom-radio.custom-radio-color-checked  input:checked[color="info"]~label::before, .custom-switch.custom-switch-color input:checked[color="info"]~label::before { background-color: #00d0ff !important; border-color: #00d0ff !important; }

/*.router-anim-enter-active{
    animation: out 2s;
    animation-delay: 1s;
}

.router-anim-leave-active{
    animation: coming 1s;
}

@keyframes coming {
    from {
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(100%);
    }
}
@keyframes out {
    0% {
        opacity: 0;
        transform: translateX(0%);
    }
    25% {
        opacity: 0;
        transform: translateX(100%);
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    100%{
        opacity: 1;
        transform: translateX(0%);
    }
}*/

.dropdown-menu:focus{
    outline: none !important;
}
.modal-backdrop {
    opacity: .5 !important;
}
.fc-button-primary:not(:disabled):active:focus, .fc-button-primary:not(:disabled).fc-button-active:focus{
    box-shadow: none !important;
}
.setting-toggle{
    padding-right: 12px;
}
.right-sidebar-panel .active{
}
.right-sidebar-panel .active::before{
}
.iq-menu-horizontal .iq-sidebar-menu .iq-menu li.active ul{ display: none !important;}
.iq-menu-horizontal .iq-sidebar-menu .iq-menu li>a::before{background: unset;}
.iq-menu-horizontal .iq-sidebar-menu .iq-menu li.active>a{color: unset;background: unset}
.tui-full-calendar-weekday-schedule-title, .tui-full-calendar-time-schedule {
    font-weight: normal !important;
}
.text-black{ color: #424242 }
.bg-gray{
    background-color: #cecece;
}

.iq-sidebar.sidebar-dark .iq-menu li.active .iq-submenu li>.router-link-exact-active {background: #1e1e1e !important; color: #fff !important;}
.iq-sidebar-menu .iq-menu li.active .iq-submenu li.active>.router-link-exact-active { background: #e0defc !important;color: var(--iq-primary) !important; border-radius: 0; }

/*---------------------------------------------------------------------
Ecommerce Pages Page
-----------------------------------------------------------------------*/
ul { list-style: none; }
ul.grid_list li a { background: #e5f2ff; color: var(--iq-primary); font-size: 18px; text-align: center; line-height: 38px; display: inline-block; width: 40px; height: 40px; margin-right: 10px; border-radius: 5px; }
.Products ul.product_list { list-style: none; margin: 0 -10px; padding: 0; }
.product-miniature { position: relative; margin-bottom: 30px; overflow: hidden; display: inline-block; vertical-align: top; }
.thumbnail-container { position: relative; overflow: hidden; }
.product-action { position: relative; float: left; transition: all 0.45s ease 0s; }
.Products li:hover .product-miniature .product-action { transition: all 0.45s ease 0s; }
.product-action > div { display: inline-block; }
.product-action a { background: #e5f2ff; color: var(--iq-primary); font-size: 18px; text-align: center; line-height: 38px; display: inline-block; width: 40px; height: 40px; margin: 0 5px; -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; }
.product-description { padding: 20px; background: #f3f7fd; border-radius: 0 0 10px 10px; -webkit-border-radius: 0 0 10px 10px; -moz-border-radius: 0 0 10px 10px; }
.product-price { }
.regular-price { color: #000; font-size: 20px; margin-bottom: 10px; }
.regular-price span { color: #777D74; text-decoration: line-through; }
.shipping span, .stock span { color: #000; }
input.increment { text-align: center; }
.ratting { margin: 0 0 10px; font-size: 18px; color: var(--iq-primary); }
.thumbnail-container img { border-radius: 10px 10px 0 0; -webkit-border-radius: 10px 10px 0 0; -moz-border-radius: 10px 10px 0 0; }
.thumbnail-container .new { position: absolute; top: 15px; left: auto; right: 15px; background: #fff; font-size: 12px; padding: 2px 7px; border-radius: 4px; }


/*---------------------------------------------------------------------
Product Page
-----------------------------------------------------------------------*/
.additional-product-action .product-action .add-to-cart a { width: auto; padding: 0 20px; background: var(--iq-primary); color: #fff; }
.description-tabs { margin-top: 100px; }
.iq-product-cover img, .iq-additional_slider img{border-radius: 5px;}
.description-tabs .nav-item .nav-link {font-size: 18px;}
#related-slider .slick-arrow {top: 45%;height: 40px;width: 40px;padding: 0;text-align: center;line-height: 40px;border-radius: 5px;}
.btn-increment button, .btn-increment input {background: transparent; border: 1px solid #f2edff;}
/*---------------------------------------------------------------------
Checkout Page
-----------------------------------------------------------------------*/
.fieldlabels { color: #455A64 !important; text-align: left }
#progressbar { margin-bottom: 30px; overflow: hidden; color: #455A64; padding-left: 0px; margin-top: 30px }
#progressbar li { list-style-type: none; font-size: 13px; width: 33.33%; float: left; position: relative; font-weight: 400 }
#progressbar #step1:before { content: "1"; color: #fff }
#progressbar #step2:before { content: "2"; color: #fff }
#progressbar #step3:before { content: "3"; color: #fff }
#progressbar li:before { width: 40px; height: 40px; line-height: 45px; display: block; font-size: 20px; background: #455A64; border-radius: 50%; margin: auto; padding: 0px }
#progressbar li:after { content: ''; width: 100%; height: 2px; background: #455A64; position: absolute; left: 0; top: 21px; z-index: -1 }
#progressbar li:last-child:after { border-top-right-radius: 10px; border-bottom-right-radius: 10px; position: absolute; left: -50% }
#progressbar li:nth-child(2):after { left: -50% }
#progressbar li:first-child:after { border-top-left-radius: 10px; border-bottom-left-radius: 10px; position: absolute; left: 50% }
#progressbar li:last-child:after { border-top-right-radius: 10px; border-bottom-right-radius: 10px }
#progressbar li:first-child:after { border-top-left-radius: 10px; border-bottom-left-radius: 10px }
#progressbar li.active:before, #progressbar li.active:after { background: var(--iq-primary) }
#progressbar li.done:before, #progressbar li.done:after { background: var(--iq-primary) }
.card-block { display: none; }
.card-block.show { display: block }
.ckeckout-product-lists {border-bottom: 1px solid rgba(0,0,0,.1);}
.ckeckout-product-lists:last-child {border-bottom: none; padding-bottom: 0 !important; margin-bottom: 0 !important;}
.iq-checkout-icon { height: 60px; width: 60px; line-height: 60px; text-align: center; font-size: 22px; }
.iq-checkout-policy ul li {border-bottom: 1px solid rgba(0,0,0,0.1);}
/* Project management */
.track .iq-card-header {border-radius: 15px 15px 0 0;}
.track .card-body {border-radius: 0 0 15px 15px;}
/* Bootstrap class */
a{color: var(--iq-primary)}
a:hover{color: var(--iq-primary)}
.btn-group-lg>.btn, .btn-lg{border-radius: 20px}
.rounded {border-radius: 15px!important;}
.rounded-circle{border-radius: 10px!important;}
.snackbar-container{border-radius: 4px !important;padding: 14px 20px;}
.btn {
    border-radius: 10px;
}
#v-pills--d-0 {background: transparent;}
.page-item.active .page-link {border-color: var(--iq-primary);background: var(--iq-primary);color: #fff !important;}
.page-link {color: var(--iq-primary) !important;}
.wizard-navigation .wizard-progress-with-circle {display: none;}
.meeting .ag-main {background: #000 !important;border-radius: 15px !important;}
.meeting.wrapper {background: #fff !important;}
.card-header{background-color: #fff !important;}
.chat-head header {border-radius: 0 15px 0 0 !important;}
.chat-footer {border-radius: 0 0 15px 0 !important;}
.chat-data {border-radius: 15px !important;}
.ag-btn-group{border-radius: 0 0 15px 15px !important;}
.iq-global-search{max-height: 300px;overflow-y: scroll;}
// .search-input{height: 73px !important;line-height: 73px !important;}
.overlay{    background: #000000a8;
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 12;
}
#iq-file-manager {
    font-family: 'Roboto', sans-serif;
    .btn-secondary {
        background: var(--iq-primary);
        background: -webkit-gradient(left top, right top, color-stop(0%, var(--iq-primary)), color-stop(100%, var(--iq-primary-light)));
        background: -webkit-gradient(linear, left top, right top, from(var(--iq-primary)), to(var(--iq-primary-light)));
        background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#827af3", endColorstr="#b47af3", GradientType=1);
        border-color: var(--iq-primary);

        &.active {
            background: rgba(130, 122, 243, 0.2);
            background: -webkit-gradient(left top, right top, color-stop(0%, rgba(130, 122, 243, 0.2)), color-stop(100%, rgba(180, 122, 243, 0.2)));
            background: -webkit-gradient(linear, left top, right top, from(rgba(130, 122, 243, 0.2)), to(rgba(180, 122, 243, 0.2)));
            background: linear-gradient(to right, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
            background: var(--iq-primary-light) !important;
        }
    }

    .badge-secondary {
        background: var(--iq-primary);
        background: -webkit-gradient(left top, right top, color-stop(0%, var(--iq-primary)), color-stop(100%, var(--iq-primary-light)));
        background: -webkit-gradient(linear, left top, right top, from(var(--iq-primary)), to(var(--iq-primary-light)));
        background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#827af3", endColorstr="#b47af3", GradientType=1);
        border-color: var(--iq-primary);
    }
    .active-manager {
        background: rgba(130, 122, 243, 0.2);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(130, 122, 243, 0.2)), color-stop(100%, rgba(180, 122, 243, 0.2)));
        background: -webkit-gradient(linear, left top, right top, from(rgba(130, 122, 243, 0.2)), to(rgba(180, 122, 243, 0.2)));
        background: linear-gradient(to right, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#827af3", endColorstr="#b47af3", GradientType=1);
        color: var(--iq-primary) !important;
        background-color: rgba(130, 122, 243, 0.2);
    }
    .table-info {
        background-color: rgba(130, 122, 243, 0.2);
        border-color:rgba(130, 122, 243, 0.2) ;
        & > tr,
        & > td,
        & > th{
            background-color: rgba(130, 122, 243, 0.2);
            border-color:rgba(130, 122, 243, 0.2) ;
        }
    }
    .fm{
        background-color: unset;
        height: 80vh !important;
    }
    .fm .fm-body{
        border-top-color: #cecece;
        border-bottom-color: #cecece;
    }
    .fm-tree{
        border-right-color: #cecece;
    }
    .fm-info-block{
        border-bottom-color: #cecece;
    }
}
.dark {
    .fm{
        background-color: unset;
        height: 80vh !important;
    }
    .fm-tree .fm-tree-disk
    {
        background-color: unset;
    }
    .fm-table thead th,
    {
        background-color: unset;
    }
    .fm-tree-branch li > p:hover, .fm-tree-branch li > p.selected
    {
        background-color: #1d203fad;
    }

    #iq-file-manager {
        .fm .fm-body{
            border-top-color: rgb(181, 134, 239);
            border-bottom-color: rgb(181, 134, 239);
        }
        .fm-tree{
            border-right-color: rgb(181, 134, 239);
        }
        .fm-info-block{
            border-bottom-color:rgb(181, 134, 239);
        }

        .table-sm > thead tr:hover,.table-sm > tbody tr:hover, .table-sm > thead th:hover{
            background-color: #1d203fad;
        }
    }
}

[dir=ltr] .btn i:not(.ri-2x) {
    font-size: 16px;
}

.multiselect .multiselect__select {
    height: 36px;
}
.multiselect__tag {
    overflow: inherit !important;
}
.multiselect .multiselect__tags {
    min-height: 36px;
    border-radius: 5px;
    padding: 5px 40px 5px 8px;
}
.multiselect__placeholder {
    color: #000000 !important;
    margin: 0 !important;
    padding: 0 !important;
}

.filter-btn button {
    height: 36px;
    margin: 22px 0 0;
    width: 100%;
}

/* top-tab-horizontal */
.top-tab-horizontal .iq-top-navbar .iq-sidebar-logo .top-logo { display: block; }
.top-tab-horizontal .iq-sidebar-logo { padding: 0; display: block; }
.top-tab-horizontal .content-page { padding: 90px 15px 0; width: 100%; margin-left: 0; }
.top-tab-horizontal .iq-footer { margin-left: 0; }
.tab-menu-horizontal { background: var(--iq-primary) !important;  margin-top: 75px; transition: all 0.45s ease 0s; position: fixed; top: 0; left: 0; right: 0; z-index: 12; }
body.top-tab-horizontal .tab-menu-horizontal.menu-down { top: -60px; transition: all 0.45s ease 0s; opacity: 0; }
body.top-tab-horizontal .tab-menu-horizontal.menu-up { top: 0; opacity: 1; transition: all 0.45s ease 0s; }
.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li > ul.iq-submenu { display: none; position: absolute; top: 150%; background: var(--iq-white); min-width: 260px; z-index: 9; padding: 0; opacity: 0; visibility: hidden; box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.2); transition: all 0.45s ease 0s; border-radius: 0 0 8px 8px; }
.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li:hover > ul.iq-submenu { display: block; opacity: 1; visibility: visible; top: 100%; transition: all 0.45s ease 0s; }
.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li > ul.iq-submenu#ui-elements { height: 350px; overflow-y: scroll; }
.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li > ul.iq-submenu ul.iq-submenu { left: 130%; top: 0; padding: 0; opacity: 0; visibility: hidden; display: block; transition: all 0.45s ease 0s; border-radius: 0 8px 8px 8px; }
.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li > ul.iq-submenu li:hover ul.iq-submenu { left: 100%; opacity: 1; visibility: visible; transition: all 0.45s ease 0s; }
.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li { padding: 10px 0; margin: 0; }
.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li.active { margin: 0; }
.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li.active .iq-submenu li.active>a { background-color: transparent; }
.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li > a {background: transparent; border-radius: 8px; padding: 10px 15px; color: var(--iq-white); transition: all 0.5s ease-out 0s; }
.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li a[aria-expanded="true"] { background: transparent; }
.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li:hover > a,
.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li > a.active { color: var(--iq-primary-dark); background: var(--iq-white); transition: all 0.5s ease-out 0s; }
body.top-tab-horizontal .iq-top-navbar .top-menu { border-radius: 0; margin-bottom: 0; padding: 0; min-height: 73px; position: fixed; top: 0; left: 0; width: 100%; display: inline-block; z-index: 99; background: var(--iq-white); -webkit-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1); -moz-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1); box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1); }
body.top-tab-horizontal .iq-top-navbar { position: static; box-shadow: none; }

.icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-size: contain;
    &.icon-customers {
        background-image: url('../images/icon-customer.png');
    }
    &.icon-cancellation {
        background-image: url('../images/icon-cancellation.png');
    }
    &.icon-forms {
        background-image: url('../images/icon-anfragen.png');
    }
    &.icon-status {
        background-image: url('../images/icon-status.png');
    }
    &.icon-orders {
        background-image: url('../images/icon-orders.png');
    }
    &.icon-gewinnspiel {
        background-image: url('../images/icon-gewinnspiel.png');
    }
    &.icon-mails {
        background-image: url('../images/icon-mails.png');
    }
    &.icon-miles {
        background-image: url('../images/icon-miles.png');
    }
    &.icon-newsletter {
        background-image: url('../images/icon-newsletter.png');
    }
}
/* css for newsletter page top margin */
.margin-top-25{
    margin-top: 25px;
  }