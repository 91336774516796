@charset "UTF-8";

/**
 * Project:           FCNM Dashboard
 * Version:           1.0.0
 * Author:            Umair Ishtiaq
 * Author URI:        https://umairishtiaq.com
 */

/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "../../node_modules/animate.css/animate.min.css";

@import "../fonts/remixicon.css";

@import "../fonts/fontawesome.css";

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;

0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: var(--iq-primary);
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #827af3;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.h1,
h1 {
  font-size: 2.5rem;
}

.h2,
h2 {
  font-size: 2rem;
}

.h3,
h3 {
  font-size: 1.75rem;
}

.h4,
h4 {
  font-size: 1.5rem;
}

.h5,
h5 {
  font-size: 1.25rem;
}

.h6,
h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.333333%;
  }

  .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }

  .offset-md-2 {
    margin-left: 16.666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.333333%;
  }

  .offset-md-5 {
    margin-left: 41.666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.333333%;
  }

  .offset-md-8 {
    margin-left: 66.666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.333333%;
  }

  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.333333%;
  }

  .offset-lg-2 {
    margin-left: 16.666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.333333%;
  }

  .offset-lg-5 {
    margin-left: 41.666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.333333%;
  }

  .offset-lg-8 {
    margin-left: 66.666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.333333%;
  }

  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.333333%;
  }

  .offset-xl-2 {
    margin-left: 16.666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.333333%;
  }

  .offset-xl-5 {
    margin-left: 41.666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.333333%;
  }

  .offset-xl-8 {
    margin-left: 66.666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.333333%;
  }

  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #b8daff;
}

.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #d6d8db;
}

.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > td,
.table-success > th {
  background-color: #c3e6cb;
}

.table-success tbody + tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > td,
.table-info > th {
  background-color: #bee5eb;
}

.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #ffeeba;
}

.table-warning tbody + tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #f5c6cb;
}

.table-danger tbody + tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe;
}

.table-light tbody + tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c6c8ca;
}

.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: rgba(50, 93, 133, 0.8588235294) !important;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 92, 199, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[multiple],
select.form-control[size] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}

.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}

.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}

.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}

.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 1rem;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 92, 199, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2f48c5;
  border-color: #2f48c5;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #068391;
  border-color: #058997;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background: #827af3;
  border-color: #827af3;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning.focus,
.btn-warning:focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light.focus,
.btn-light:focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark.focus,
.btn-dark:focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 92, 199, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #827af3;
  border-color: #827af3;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 92, 199, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 20px;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  line-height: normal;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group-vertical > .btn:hover,
.btn-group > .btn:hover {
  z-index: 1;
}

.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus {
  z-index: 1;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .form-control {
  margin-left: -1px;
}

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-append,
.input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}

.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  z-index: 3;
}

.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
  margin-top: 0;
}

.input-group-lg > .custom-select,
.input-group-lg > .form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .custom-select,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .custom-select,
.input-group-sm > .form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #827af3;
  background-color: #827af3;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(71, 92, 199, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label,
.custom-control-input[disabled] ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before,
.custom-control-input[disabled] ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(71, 92, 199, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(71, 92, 199, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(71, 92, 199, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(71, 92, 199, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 92, 199, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(71, 92, 199, 0.25);
}

.custom-file-input:disabled ~ .custom-file-label,
.custom-file-input[disabled] ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(71, 92, 199, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(71, 92, 199, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(71, 92, 199, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 10px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

h3.card-title {
  font-size: 1.25em;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-bottom,
.card-img-top {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #827af3;
  background-color: #fff;
  border: 1px solid #827af3;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #827af3;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 92, 199, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #827af3;
  border-color: #827af3;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #827af3;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:focus,
a.badge:hover {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 5px;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc;
}

a.badge-primary.focus,
a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 92, 199, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary.focus,
a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success.focus,
a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:focus,
a.badge-info:hover {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info.focus,
a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:focus,
a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger.focus,
a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:focus,
a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light.focus,
a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark.focus,
a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto[x-placement^=top],
.bs-popover-top {
  margin-bottom: 0.5rem;
}

.bs-popover-auto[x-placement^=top] > .arrow,
.bs-popover-top > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^=top] > .arrow::before,
.bs-popover-top > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^=top] > .arrow::after,
.bs-popover-top > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-auto[x-placement^=right],
.bs-popover-right {
  margin-left: 0.5rem;
}

.bs-popover-auto[x-placement^=right] > .arrow,
.bs-popover-right > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^=right] > .arrow::before,
.bs-popover-right > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^=right] > .arrow::after,
.bs-popover-right > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-auto[x-placement^=bottom],
.bs-popover-bottom {
  margin-top: 0.5rem;
}

.bs-popover-auto[x-placement^=bottom] > .arrow,
.bs-popover-bottom > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^=bottom] > .arrow::before,
.bs-popover-bottom > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^=bottom] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-auto[x-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-auto[x-placement^=left],
.bs-popover-left {
  margin-right: 0.5rem;
}

.bs-popover-auto[x-placement^=left] > .arrow,
.bs-popover-left > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^=left] > .arrow::before,
.bs-popover-left > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^=left] > .arrow::after,
.bs-popover-left > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 15px !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 10px !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote,
  pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

@page {
    size: a3;
}

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.snackbar-container {
  transition: all 500ms ease;
  transition-property: top, right, bottom, left, opacity;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  min-height: 14px;
  background-color: #070b0e;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  line-height: 22px;
  padding: 18px 24px;
  bottom: -100px;
  top: -100px;
  opacity: 0;
  z-index: 9999;
}

.snackbar-container .action {
  background: inherit;
  display: inline-block;
  border: none;
  font-size: inherit;
  text-transform: uppercase;
  color: #4caf50;
  margin: 0 0 0 24px;
  padding: 0;
  min-width: min-content;
  cursor: pointer;
}

@media (min-width: 640px) {
  .snackbar-container {
    min-width: 288px;
    max-width: 568px;
    display: inline-flex;
    border-radius: 2px;
    margin: 24px;
  }
}

@media (max-width: 640px) {
  .snackbar-container {
    left: 0;
    right: 0;
    width: 100%;
  }
}

.snackbar-pos.bottom-center {
  top: auto !important;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.snackbar-pos.bottom-left {
  top: auto !important;
  bottom: 0;
  left: 0;
}

.snackbar-pos.bottom-right {
  top: auto !important;
  bottom: 0;
  right: 0;
}

.snackbar-pos.top-left {
  bottom: auto !important;
  top: 0;
  left: 0;
}

.snackbar-pos.top-center {
  bottom: auto !important;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.snackbar-pos.top-right {
  bottom: auto !important;
  top: 0;
  right: 0;
}

@media (max-width: 640px) {
  .snackbar-pos.bottom-center,
  .snackbar-pos.top-center {
    left: 0;
    transform: none;
  }
}

:root {
  --iq-card-shadow-1: rgba(226, 190, 253,.15);
  --iq-card-shadow-2: rgba(226, 190, 253,.15);
  --iq-primary-shadow-1: rgba(78,31,249,.5);
  --iq-primary-shadow-2: rgba(78,31,249,.3);
  --iq-dropdown-shadow: rgba(0, 0, 0,.05);
  --iq-title-text: #555770;
  --iq-body-text: #555770;
  --iq-body-bg: #f8f7fd;
  --iq-card-bg: #fff;
  --iq-dropdown-bg: #fff;
  --iq-dropdown-border: #fff;
  --iq-primary: #325D85;
  --iq-primary-light: #478BCA;
  --iq-primary-dark: #0A133B;
  --iq-secondary: #EBB834;
  --iq-success: #198754;
  --iq-warning: #FAD02E;
  --iq-danger: #DC3545;
  --iq-info: #00CFDE;
  --iq-dark: #999999;
  --iq-light: #FDFAFF;
  --iq-white: #ffffff;
  --iq-black: #000000;
  --iq-border: #4180AE;
  --iq-bg: #F3F3F3;
  --iq-bg-light: #F9F9F9;
}

/*
Template: FCNM - Dashboard
Author: umairishtiaq.com
Design and Developed by: Umair Ishtiaq
NOTE: This file contains the styling for responsive Template.
*/

/*================================================
[  Table of contents  ]
================================================

:: Font
:: Import Css
:: General
:: Input
:: Loading
:: Background Color
:: Background Color Opacity
:: Text Color
:: Line Height
:: Font Size
:: Avatar Size
:: Margin Bottom
:: Card
:: Background overlay color
:: Buttons
:: Alert
:: list Group

======================================
[ End table content ]
======================================*/

/*---------------------------------------------------------------------
General
-----------------------------------------------------------------------*/

*::-moz-selection {
  background: var(--iq-primary);
  color: #ffffff;
  text-shadow: none;
}

::-moz-selection {
  background: var(--iq-primary);
  color: #ffffff;
  text-shadow: none;
}

::selection {
  background: var(--iq-primary);
  color: #ffffff;
  text-shadow: none;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 1.8;
  padding: 0;
  margin: 0;
  color: var(--iq-dark);
  background: var(--iq-bg);
}

a,
.btn {
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

a,
button,
input,
btn {
  outline: medium none !important;
}

.uppercase {
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  margin: 0px;
  line-height: 1.5;
  color: var(--iq-primary-dark);
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

label {
  font-weight: normal;
}

h1 {
  font-size: 3.052em;
}

h2 {
  font-size: 2.3em;
}

h3 {
  font-size: 1.953em;
}

h4 {
  font-size: 1.2em;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-size: 1em;
}

/*----------------------------------------------
Input
------------------------------------------------*/

label {
  color: var(--iq-border);
  margin-bottom: 0;
}

.form-control {
  height: 36px;
  line-height: 36px;
  background: transparent;
  border: 1px solid var(--iq-border);
  font-size: 14px;
  color: var(--iq-dark);
  border-radius: 5px;
}

.form-control::placeholder {
  color: #FFFFFF;
}

.form-control:focus {
  color: var(--iq-dark);
  border-color: var(--iq-primary);
  box-shadow: none;
}

/* Definition Lists */

dl dd {
  margin-bottom: 15px;
}

dl dd:last-child {
  margin-bottom: 0px;
}

/*----------------------------------------------
loading
------------------------------------------------*/

#loading {
  height: 100%;
  width: 100%;
  background-size: 10% !important;
  position: fixed;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  z-index: 999999;
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

.loader {
  width: 3em;
  height: 3em;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotateX(-37.5deg) rotateY(45deg);
    transform: rotateX(-37.5deg) rotateY(45deg);
  }

  50% {
    -webkit-transform: rotateX(-37.5deg) rotateY(405deg);
    transform: rotateX(-37.5deg) rotateY(405deg);
  }

  100% {
    -webkit-transform: rotateX(-37.5deg) rotateY(405deg);
    transform: rotateX(-37.5deg) rotateY(405deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotateX(-37.5deg) rotateY(45deg);
    transform: rotateX(-37.5deg) rotateY(45deg);
  }

  50% {
    -webkit-transform: rotateX(-37.5deg) rotateY(405deg);
    transform: rotateX(-37.5deg) rotateY(405deg);
  }

  100% {
    -webkit-transform: rotateX(-37.5deg) rotateY(405deg);
    transform: rotateX(-37.5deg) rotateY(405deg);
  }
}

.cube,
.cube * {
  position: absolute;
  width: 71px;
  height: 71px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.sides {
  -webkit-animation: rotate 3s ease infinite;
  animation: rotate 3s ease infinite;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-37.5deg) rotateY(45deg);
  transform: rotateX(-37.5deg) rotateY(45deg);
}

.cube .sides * {
  box-sizing: border-box;
  background-color: rgba(130, 122, 243, 0.8);
  border: 5px solid #eaeaea;
}

.cube .sides .top {
  -webkit-animation: top-animation 3s ease infinite;
  animation: top-animation 3s ease infinite;
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
  -webkit-transform: rotateX(90deg) translateZ(90px);
  transform: rotateX(90deg) translateZ(90px);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

@-webkit-keyframes top-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(90px);
    transform: rotateX(90deg) translateZ(90px);
  }

  20% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(35px);
    transform: rotateX(90deg) translateZ(35px);
  }

  70% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(35px);
    transform: rotateX(90deg) translateZ(35px);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(90px);
    transform: rotateX(90deg) translateZ(90px);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(90px);
    transform: rotateX(90deg) translateZ(90px);
  }
}

@keyframes top-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(90px);
    transform: rotateX(90deg) translateZ(90px);
  }

  20% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(35px);
    transform: rotateX(90deg) translateZ(35px);
  }

  70% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(35px);
    transform: rotateX(90deg) translateZ(35px);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(90px);
    transform: rotateX(90deg) translateZ(90px);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(90px);
    transform: rotateX(90deg) translateZ(90px);
  }
}

.cube .sides .bottom {
  -webkit-animation: bottom-animation 3s ease infinite;
  animation: bottom-animation 3s ease infinite;
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
  -webkit-transform: rotateX(-90deg) translateZ(90px);
  transform: rotateX(-90deg) translateZ(90px);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

@-webkit-keyframes bottom-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(90px);
    transform: rotateX(-90deg) translateZ(90px);
  }

  20% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(35px);
    transform: rotateX(-90deg) translateZ(35px);
  }

  70% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(35px);
    transform: rotateX(-90deg) translateZ(35px);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(90px);
    transform: rotateX(-90deg) translateZ(90px);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(90px);
    transform: rotateX(-90deg) translateZ(90px);
  }
}

@keyframes bottom-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(90px);
    transform: rotateX(-90deg) translateZ(90px);
  }

  20% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(35px);
    transform: rotateX(-90deg) translateZ(35px);
  }

  70% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(35px);
    transform: rotateX(-90deg) translateZ(35px);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(90px);
    transform: rotateX(-90deg) translateZ(90px);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(90px);
    transform: rotateX(-90deg) translateZ(90px);
  }
}

.cube .sides .front {
  -webkit-animation: front-animation 3s ease infinite;
  animation: front-animation 3s ease infinite;
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
  -webkit-transform: rotateY(0deg) translateZ(90px);
  transform: rotateY(0deg) translateZ(90px);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

@-webkit-keyframes front-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(90px);
    transform: rotateY(0deg) translateZ(90px);
  }

  20% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(35px);
    transform: rotateY(0deg) translateZ(35px);
  }

  70% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(35px);
    transform: rotateY(0deg) translateZ(35px);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(90px);
    transform: rotateY(0deg) translateZ(90px);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(90px);
    transform: rotateY(0deg) translateZ(90px);
  }
}

@keyframes front-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(90px);
    transform: rotateY(0deg) translateZ(90px);
  }

  20% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(35px);
    transform: rotateY(0deg) translateZ(35px);
  }

  70% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(35px);
    transform: rotateY(0deg) translateZ(35px);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(90px);
    transform: rotateY(0deg) translateZ(90px);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(90px);
    transform: rotateY(0deg) translateZ(90px);
  }
}

.cube .sides .back {
  -webkit-animation: back-animation 3s ease infinite;
  animation: back-animation 3s ease infinite;
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
  -webkit-transform: rotateY(-180deg) translateZ(90px);
  transform: rotateY(-180deg) translateZ(90px);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

@-webkit-keyframes back-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(90px);
    transform: rotateY(-180deg) translateZ(90px);
  }

  20% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(35px);
    transform: rotateY(-180deg) translateZ(35px);
  }

  70% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(35px);
    transform: rotateY(-180deg) translateZ(35px);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(90px);
    transform: rotateY(-180deg) translateZ(90px);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(90px);
    transform: rotateY(-180deg) translateZ(90px);
  }
}

@keyframes back-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(90px);
    transform: rotateY(-180deg) translateZ(90px);
  }

  20% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(35px);
    transform: rotateY(-180deg) translateZ(35px);
  }

  70% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(35px);
    transform: rotateY(-180deg) translateZ(35px);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(90px);
    transform: rotateY(-180deg) translateZ(90px);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(90px);
    transform: rotateY(-180deg) translateZ(90px);
  }
}

.cube .sides .left {
  -webkit-animation: left-animation 3s ease infinite;
  animation: left-animation 3s ease infinite;
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
  -webkit-transform: rotateY(-90deg) translateZ(90px);
  transform: rotateY(-90deg) translateZ(90px);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

@-webkit-keyframes left-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(90px);
    transform: rotateY(-90deg) translateZ(90px);
  }

  20% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(35px);
    transform: rotateY(-90deg) translateZ(35px);
  }

  70% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(35px);
    transform: rotateY(-90deg) translateZ(35px);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(90px);
    transform: rotateY(-90deg) translateZ(90px);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(90px);
    transform: rotateY(-90deg) translateZ(90px);
  }
}

@keyframes left-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(90px);
    transform: rotateY(-90deg) translateZ(90px);
  }

  20% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(35px);
    transform: rotateY(-90deg) translateZ(35px);
  }

  70% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(35px);
    transform: rotateY(-90deg) translateZ(35px);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(90px);
    transform: rotateY(-90deg) translateZ(90px);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(90px);
    transform: rotateY(-90deg) translateZ(90px);
  }
}

.cube .sides .right {
  -webkit-animation: right-animation 3s ease infinite;
  animation: right-animation 3s ease infinite;
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
  -webkit-transform: rotateY(90deg) translateZ(90px);
  transform: rotateY(90deg) translateZ(90px);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

@-webkit-keyframes right-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(90px);
    transform: rotateY(90deg) translateZ(90px);
  }

  20% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(35px);
    transform: rotateY(90deg) translateZ(35px);
  }

  70% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(35px);
    transform: rotateY(90deg) translateZ(35px);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(90px);
    transform: rotateY(90deg) translateZ(90px);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(90px);
    transform: rotateY(90deg) translateZ(90px);
  }
}

@keyframes right-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(90px);
    transform: rotateY(90deg) translateZ(90px);
  }

  20% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(35px);
    transform: rotateY(90deg) translateZ(35px);
  }

  70% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(35px);
    transform: rotateY(90deg) translateZ(35px);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(90px);
    transform: rotateY(90deg) translateZ(90px);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(90px);
    transform: rotateY(90deg) translateZ(90px);
  }
}

.right-sidebar-mini {
  top: 0;
  z-index: 100;
  position: fixed;
  width: 350px;
  right: 0;
  transform: translateX(calc(111% - 2em));
  transition: all 0.5s ease;
}

.right-sidebar-mini .side-right-icon {
  display: none;
}

.right-sidebar-toggle {
  position: absolute;
  margin-left: -44px;
  background: #fff;
  padding: 15px;
  display: inline;
  top: 15%;
  z-index: 99;
  border-radius: 30px 0px 0px 30px !important;
  box-shadow: -10px 5px 20px rgba(0, 0, 0, 0.19) !important;
  cursor: pointer;
}

.right-sidebar-panel {
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  height: 100vh;
  padding: 15px;
  overflow-y: scroll;
}

.right-sidebar {
  transform: translateX(calc(10% - 1em));
}

.right-sidebar .side-left-icon {
  display: none;
}

.right-sidebar .side-right-icon {
  display: block;
}

/*----------------------------------------------
Background Color
------------------------------------------------*/

.bg-primary,
.badge-primary {
  color: #ffffff;
  background: var(--iq-primary) !important;
  background: -moz-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%) !important;
  background: -webkit-gradient(left top, right top, color-stop(0%, var(--iq-primary)), color-stop(100%, var(--iq-primary-light))) !important;
  background: -webkit-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%) !important;
  background: -o-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%) !important;
  background: -ms-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%) !important;
  background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#827af3", endColorstr="#b47af3", GradientType=1) !important;
}

.bg-secondary,
.badge-secondary {
  color: #ffffff;
  background-color: var(--iq-secondary) !important;
}

.bg-success,
.badge-success {
  color: #ffffff;
  background: var(--iq-success);
  background: -moz-linear-gradient(left, var(--iq-success) 0%, var(--iq-success) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, var(--iq-success)), color-stop(100%, var(--iq-success)));
  background: -webkit-linear-gradient(left, var(--iq-success) 0%, var(--iq-success) 100%);
  background: -o-linear-gradient(left, var(--iq-success) 0%, var(--iq-success) 100%);
  background: -ms-linear-gradient(left, var(--iq-success) 0%, var(--iq-success) 100%);
  background: linear-gradient(to right, var(--iq-success) 0%, var(--iq-success) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3dbb58", endColorstr="#45ce62", GradientType=1);
}

.bg-danger,
.badge-danger {
  color: #ffffff;
  background: var(--iq-danger);
  background: -moz-linear-gradient(left, var(--iq-danger) 0%, var(--iq-danger) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, var(--iq-danger)), color-stop(100%, var(--iq-danger)));
  background: -webkit-linear-gradient(left, var(--iq-danger) 0%, var(--iq-danger) 100%);
  background: -o-linear-gradient(left, var(--iq-danger) 0%, var(--iq-danger) 100%);
  background: -ms-linear-gradient(left, var(--iq-danger) 0%, var(--iq-danger) 100%);
  background: linear-gradient(to right, var(--iq-danger) 0%, var(--iq-danger) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f35448", endColorstr="#fd6256", GradientType=1);
}

.bg-warning,
.badge-warning {
  color: #ffffff;
  background: var(--iq-warning);
  background: -moz-linear-gradient(left, var(--iq-warning) 0%, var(--iq-warning) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, var(--iq-warning)), color-stop(100%, var(--iq-warning)));
  background: -webkit-linear-gradient(left, var(--iq-warning) 0%, var(--iq-warning) 100%);
  background: -o-linear-gradient(left, var(--iq-warning) 0%, var(--iq-warning) 100%);
  background: -ms-linear-gradient(left, var(--iq-warning) 0%, var(--iq-warning) 100%);
  background: linear-gradient(to right, var(--iq-warning) 0%, var(--iq-warning) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="var(--iq-warning)", endColorstr="#ffd369", GradientType=1);
}

.bg-info,
.badge-info {
  color: #ffffff;
  background: var(--iq-info);
  background: -moz-linear-gradient(left, var(--iq-info) 0%, var(--iq-info) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, var(--iq-info)), color-stop(100%, var(--iq-info)));
  background: -webkit-linear-gradient(left, var(--iq-info) 0%, var(--iq-info) 100%);
  background: -o-linear-gradient(left, var(--iq-info) 0%, var(--iq-info) 100%);
  background: -ms-linear-gradient(left, var(--iq-info) 0%, var(--iq-info) 100%);
  background: linear-gradient(to right, var(--iq-info) 0%, var(--iq-info) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#67ddea", endColorstr="var(--iq-info))", GradientType=1);
}

.bg-light,
.badge-light {
  background-color: var(--iq-light) !important;
}

.bg-dark,
.badge-dark {
  color: #ffffff;
  background-color: var(--iq-dark) !important;
}

/*----------------------------------------------
Background Color Opacity
------------------------------------------------*/

.iq-bg-primary {
  background: rgba(130, 122, 243, 0.2);
  background: -moz-linear-gradient(left, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(130, 122, 243, 0.2)), color-stop(100%, rgba(180, 122, 243, 0.2)));
  background: -webkit-linear-gradient(left, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  background: -o-linear-gradient(left, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  background: -ms-linear-gradient(left, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  background: linear-gradient(to right, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#827af3", endColorstr="#b47af3", GradientType=1);
  color: var(--iq-primary) !important;
}

.iq-bg-secondary {
  background: #f1f2f1 !important;
  color: var(--iq-secondary) !important;
}

.iq-bg-success {
  background: #eefff2 !important;
  color: var(--iq-success) !important;
}

.iq-bg-danger {
  background: #fff5f4 !important;
  color: var(--iq-danger) !important;
}

.iq-bg-warning {
  background: #fffcf3 !important;
  color: var(--iq-warning) !important;
}

.iq-bg-info {
  background: #e5fafc !important;
  color: var(--iq-info) !important;
}

.iq-bg-dark {
  background: #f0efef !important;
  color: var(--iq-dark) !important;
}

/*--------------*/

.iq-bg-primary-hover:hover {
  background: #e0defc !important;
}

.iq-bg-primary-secondary-hover:hover {
  background: #f1f2f1 !important;
}

.iq-bg-primary-success-hover:hover {
  background: #eefff2 !important;
}

.iq-bg-primary-danger-hover:hover {
  background: #fff5f4 !important;
}

.iq-bg-primary-warning-hover:hover {
  background: #fffcf3 !important;
}

.iq-bg-primary-info-hover:hover {
  background: #e5fafc !important;
}

.iq-bg-primary-dark-hover:hover {
  background: #f0efef !important;
}

/*----------------------------------------------
Text Color
------------------------------------------------*/

.text-primary {
  color: var(--iq-primary) !important;
}

.text-secondary {
  color: var(--iq-secondary) !important;
}

.text-success {
  color: var(--iq-success) !important;
}

.text-danger {
  color: var(--iq-danger) !important;
}

.text-warning {
  color: var(--iq-warning) !important;
}

.text-info {
  color: var(--iq-info) !important;
}

.text-light {
  color: var(--iq-light) !important;
}

.text-dark {
  color: var(--iq-dark) !important;
}

.text-white {
  color: #ffffff !important;
}

/*----------------------------------------------
Line Height
------------------------------------------------*/

.line-height {
  line-height: normal;
}

.line-height-2 {
  line-height: 2;
}

.line-height-4 {
  line-height: 40px;
}

.line-height-6 {
  line-height: 60px;
}

/*----------------------------------------------
Font Size
------------------------------------------------*/

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-40 {
  font-size: 40px;
}

/*----------------------------------------------
Avatar Size
------------------------------------------------*/

.avatar-35 {
  height: 35px;
  width: 35px;
  line-height: 35px;
  font-size: 0.5rem;
}

.avatar-30 {
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 0.4rem;
}

.avatar-40 {
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-size: 0.6rem;
}

.avatar-45 {
  height: 45px;
  width: 45px;
  line-height: 45px;
  font-size: 0.8rem;
}

.avatar-50 {
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 1rem;
}

.avatar-60 {
  height: 60px;
  width: 60px;
  line-height: 60px;
  font-size: 1.2rem;
}

.avatar-70 {
  height: 70px;
  width: 70px;
  line-height: 70px;
  font-size: 1.4rem;
}

.avatar-80 {
  height: 80px;
  width: 80px;
  line-height: 80px;
  font-size: 1.6rem;
}

.avatar-90 {
  height: 90px;
  width: 90px;
  line-height: 90px;
  font-size: 1.6rem;
}

.avatar-100 {
  height: 100px;
  width: 100px;
  line-height: 100px;
  font-size: 1.6rem;
}

.avatar-110 {
  height: 110px;
  width: 110px;
  line-height: 110px;
  font-size: 1.6rem;
}

.avatar-120 {
  height: 120px;
  width: 120px;
  line-height: 120px;
  font-size: 1.6rem;
}

.avatar-130 {
  height: 130px;
  width: 130px;
  line-height: 130px;
  font-size: 1.6rem;
}

/*----------------------------------------------
Margin Bottom
------------------------------------------------*/

.iq-mb-3 {
  margin-bottom: 30px !important;
}

/*----------------------------------------------
Card
------------------------------------------------*/

.iq-card {
  background: #ffffff;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  margin-bottom: 30px;
  border: none;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(120, 146, 141, 0.06);
  box-shadow: 0px 0px 12px 0px rgba(120, 146, 141, 0.06);
}

.iq-card-body {
  padding: 20px;
}

.iq-card .iq-card-header {
  padding: 20px 20px 0;
  min-height: 39px;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.iq-card .iq-card-header .iq-header-title {
  display: inline-block;
  align-self: center !important;
}

.iq-card .iq-card-header .iq-header-title .card-title {
  margin-bottom: 0;
}

.iq-card-header-toolbar .nav-item a {
  color: #393c52;
  padding: 4px 12px;
  font-size: 14px;
  font-family: "Nunito", sans-serif;
}

.iq-card-header-toolbar .dropdown-toggle i {
  font-size: 22px;
  line-height: normal;
  color: #393c52;
}

.iq-card-header-toolbar .dropdown-toggle::after {
  display: none;
}

/*--------------*/

.nav-pills .nav-item a {
  color: #393c52;
  font-family: "Roboto", sans-serif;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--iq-primary);
  background: #e0defc;
}

.nav-pills .nav-link:hover {
  color: var(--iq-primary);
}

/*--------------*/

.nav-tabs {
  border-bottom: 2px solid #f2edff;
  margin-bottom: 15px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs .nav-item a {
  color: #393c52;
  font-family: "Roboto", sans-serif;
  border: none;
  border-bottom: 2px solid transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 2px solid var(--iq-primary);
  color: var(--iq-primary);
}

/*---------------------------------------------------------------------
Background overlay color
-----------------------------------------------------------------------*/

.parallax {
  background-size: cover !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -ms-background-size: cover !important;
  position: relative;
  z-index: 0;
  background-origin: initial;
  background-position: center center !important;
  background-repeat: no-repeat;
  background-attachment: fixed !important;
}

/* Background Gradient BLACK */

.bg-over-black-10:before {
  background: rgba(5, 8, 9, 0.1);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-over-black-20:before {
  background: rgba(5, 8, 9, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-over-black-30:before {
  background: rgba(5, 8, 9, 0.3);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-over-black-40:before {
  background: rgba(5, 8, 9, 0.4);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-over-black-50:before {
  background: rgba(5, 8, 9, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-over-black-60:before {
  background: rgba(5, 8, 9, 0.6);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-over-black-70:before {
  background: rgba(5, 8, 9, 0.7);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-over-black-80:before {
  background: rgba(5, 8, 9, 0.8);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-over-black-85:before {
  background: rgba(5, 8, 9, 0.85);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-over-black-90:before {
  background: rgba(5, 8, 9, 0.9);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

/*----------------------------------------------
Buttons
------------------------------------------------*/

.btn {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.btn i {
  margin-right: 5px;
}

.iq-sign-btn {
  display: inline-block;
  text-align: center;
  border: 1px solid transparent;
  padding: 0.375rem 1rem;
  border-radius: 15px;
}

.iq-sign-btn:hover {
  color: #ffffff !important;
  background: var(--iq-danger) !important;
}

/*--------------*/

.btn-primary {
  background: var(--iq-primary);
  background: -moz-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, var(--iq-primary)), color-stop(100%, var(--iq-primary-light)));
  background: -webkit-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: -o-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: -ms-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#827af3", endColorstr="#b47af3", GradientType=1);
  border-color: var(--iq-primary);
}

.btn-secondary {
  background-color: var(--iq-secondary);
  border-color: var(--iq-secondary);
}

.btn-success {
  background-color: var(--iq-success);
  border-color: var(--iq-success);
}

.btn-danger {
  background-color: var(--iq-danger);
  border-color: var(--iq-danger);
}

.btn-warning {
  background-color: var(--iq-warning);
  border-color: var(--iq-warning);
}

.btn-info {
  background-color: var(--iq-info);
  border-color: var(--iq-info);
}

.btn-light {
  background-color: var(--iq-light);
  border-color: var(--iq-light);
}

.btn-dark {
  background-color: var(--iq-dark);
  border-color: var(--iq-dark);
}

/*--------------*/

.btn-primary:hover {
  background: var(--iq-primary-light);
  background: -moz-linear-gradient(left, var(--iq-primary-light) 0%, var(--iq-primary) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, var(--iq-primary-light)), color-stop(100%, var(--iq-primary)));
  background: -webkit-linear-gradient(left, var(--iq-primary-light) 0%, var(--iq-primary) 100%);
  background: -o-linear-gradient(left, var(--iq-primary-light) 0%, var(--iq-primary) 100%);
  background: -ms-linear-gradient(left, var(--iq-primary-light) 0%, var(--iq-primary) 100%);
  background: linear-gradient(to right, var(--iq-primary-light) 0%, var(--iq-primary) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b47af3", endColorstr="#827af3", GradientType=1);
  border-color: var(--iq-primary);
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background: var(--iq-primary-light) !important;
  background: -moz-linear-gradient(left, var(--iq-primary-light) 0%, var(--iq-primary) 100%) !important;
  background: -webkit-gradient(left top, right top, color-stop(0%, var(--iq-primary-light)), color-stop(100%, var(--iq-primary)));
  background: -webkit-linear-gradient(left, var(--iq-primary-light) 0%, var(--iq-primary) 100%) !important;
  background: -o-linear-gradient(left, var(--iq-primary-light) 0%, var(--iq-primary) 100%) !important;
  background: -ms-linear-gradient(left, var(--iq-primary-light) 0%, var(--iq-primary) 100%) !important;
  background: linear-gradient(to right, var(--iq-primary-light) 0%, var(--iq-primary) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b47af3", endColorstr="#827af3", GradientType=1);
  border-color: var(--iq-primary);
}

/*--------------*/

.btn-outline-primary {
  color: var(--iq-primary);
  border-color: var(--iq-primary);
}

.btn-outline-secondary {
  color: var(--iq-secondary);
  border-color: var(--iq-secondary);
}

.btn-outline-success {
  color: var(--iq-success);
  border-color: var(--iq-success);
}

.btn-outline-danger {
  color: var(--iq-danger);
  border-color: var(--iq-danger);
}

.btn-outline-warning {
  color: var(--iq-warning);
  border-color: var(--iq-warning);
}

.btn-outline-info {
  color: var(--iq-info);
  border-color: var(--iq-info);
}

.btn-outline-light {
  color: var(--iq-light);
  border-color: var(--iq-light);
}

.btn-outline-dark {
  color: var(--iq-dark);
  border-color: var(--iq-dark);
}

/*----------------------------------------------
Alert
------------------------------------------------*/

.alert-primary {
  color: var(--iq-primary);
  border-color: var(--iq-primary);
  background-color: var(--iq-bg-light-color);
}

.alert-secondary {
  color: var(--iq-secondary);
  border-color: #d6d8d5;
  background-color: #f1f2f1;
}

.alert-success {
  color: var(--iq-success);
  border-color: #b2efb2;
  background-color: #eefff2;
}

.alert-danger {
  color: var(--iq-danger);
  border-color: #f7c6c6;
  background-color: #fff5f4;
}

.alert-warning {
  color: var(--iq-warning);
  border-color: #fff2b2;
  background-color: #fffcf3;
}

.alert-info {
  color: var(--iq-info);
  border-color: #b2f1ff;
  background-color: #e5fafc;
}

.alert-light {
  color: var(--iq-secondary);
  border-color: #d6d8d5;
  background-color: var(--iq-light);
}

.alert-dark {
  color: var(--iq-dark);
  border-color: #c3c8c8;
  background-color: #f0efef;
}

/*--------------*/

.alert {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.alert .iq-alert-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1.3rem 0 0;
}

.alert .iq-alert-icon i {
  font-size: 2.441em;
  line-height: normal;
}

.alert .iq-alert-text {
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.alert .close {
  float: right;
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  text-shadow: none;
  opacity: 1;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  outline: none;
}

/*----------------------------------------------
list Group
------------------------------------------------*/

.list-group-item-primary {
  color: var(--iq-primary);
  background-color: var(--iq-bg-light-color);
}

.list-group-item-secondary {
  color: var(--iq-secondary);
  background-color: #f1f2f1;
}

.list-group-item-success {
  color: var(--iq-success);
  background-color: #eefff2;
}

.list-group-item-danger {
  color: var(--iq-danger);
  background-color: #fff5f4;
}

.list-group-item-warning {
  color: var(--iq-warning);
  background-color: #fffcf3;
}

.list-group-item-info {
  color: var(--iq-info);
  background-color: #e5fafc;
}

.list-group-item-light {
  color: var(--iq-secondary);
  background-color: var(--iq-light);
}

.list-group-item-dark {
  color: var(--iq-dark);
  background-color: #f0efef;
}

.list-group-item-action {
  color: var(--iq-dark);
}

.list-group-item.active {
  background-color: var(--iq-primary);
  border-color: var(--iq-primary);
}

/*
Template: FCNM - Dashboard
Author: umairishtiaq.com
Design and Developed by: Umair Ishtiaq
NOTE: This file contains the styling for responsive Template.
*/

/*================================================
[  Table of contents  ]
================================================

:: General
:: Badge
:: Media Support
:: Best Sellers
:: Profile Avatar
:: Countdown
:: Progressbar
:: Dropdown Menu
:: Badge Img Tag
:: List Style 1
:: Icon Box
:: Tasks Card
:: Sign In
:: Dash Hover
:: Waves Effect
:: Sidebar
:: Sidebar Menu
:: Top Menu
:: Content Page
:: Error Page
:: OWL Carousel
:: Timeline
:: Team
:: User Add Block
:: Email list
:: Booking list
:: Chart
:: Material Card Style
:: Circle Progressbar
:: Tikit Booking List
:: Right Sidebar Menu
:: FAQ
:: Table
:: Animation Card
:: Footer
:: Map Profile
:: Mail Inbox
:: Slick Arrow
:: Calender
:: From Wizard
:: Chat
:: Profile
:: Todo
:: Privacy Policy
:: User List
:: Add User
:: Index

======================================
[ End table content ]
======================================*/

/*--------------------------------------------------------------------
                            General
---------------------------------------------------------------------*/

*::-moz-selection {
  background: var(--iq-primary);
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: var(--iq-primary);
  color: #fff;
  text-shadow: none;
}

::selection {
  background: var(--iq-primary);
  color: #fff;
  text-shadow: none;
}

/* Definition Lists */

dl dd {
  margin-bottom: 15px;
}

dl dd:last-child {
  margin-bottom: 0px;
}

/* Table */

/* table { border: 1px solid #eaeaea; width: 100%; }
table td, table th { border: 1px solid #eaeaea; } */

a:hover {
  text-decoration: none;
}

.dropdown-item {
  font-size: 14px;
}

.dropdown-menu {
  border-radius: 10px;
}

/* scroller */

.scroller {
  overflow-y: auto;
  scrollbar-color: var(--iq-primary) #edf3ff;
  scrollbar-width: thin;
}

.scroller::-webkit-scrollbar-thumb {
  background-color: var(--iq-primary);
}

.scroller::-webkit-scrollbar-track {
  background-color: #edf3ff;
}

#sidebar-scrollbar {
  overflow-y: auto;
  scrollbar-color: var(--iq-primary) #edf3ff;
  scrollbar-width: thin;
}

#sidebar-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--iq-primary);
}

#sidebar-scrollbar::-webkit-scrollbar-track {
  background-color: #edf3ff;
}

/* Form */

#sidebar-scrollbar {
  height: 100%;
}

/*---------------------------------------------------------------------
                                  Badge
-----------------------------------------------------------------------*/

.badge {
  padding: 0.3em 0.6em;
  line-height: 1.3;
  text-transform: capitalize;
}

.breadcrumb.bg-primary .breadcrumb-item + .breadcrumb-item::before {
  color: #ffffff;
}

/*---------------------------------------------------------------------
                               Media Support
-----------------------------------------------------------------------*/

.media-support {
  display: inline-block;
  width: 100%;
}

.media-support-user-img img {
  height: 60px;
}

.media-support-header {
  display: flex;
  align-items: flex-start;
}

.media-support-info {
  flex: 1;
}

.projects-media img {
  height: 60px;
}

.iq-media-group .iq-media {
  margin-left: -20px;
  position: relative;
  z-index: 0;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.iq-media-group .iq-media:hover {
  z-index: 9;
}

.iq-media-group .iq-media img,
.iq-media-group .iq-media.border {
  border: 2px solid #ddd;
}

.iq-media-group .iq-media:first-child {
  margin-left: 0;
}

.iq-media {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.iq-media:hover {
  text-decoration: none;
}

.media-pl {
  text-align: center;
}

/*---------------------------------------------------------------------
                                  Best Sellers
-----------------------------------------------------------------------*/

.media-sellers {
  display: flex;
  align-items: flex-start;
}

.media-sellers-media-info {
  flex: 1;
}

.media-sellers-img img {
  height: 80px;
}

.iq-card .list-star li {
  margin-right: 0;
}

/*---------------------------------------------------------------------
                                  Profile-Avatar
-----------------------------------------------------------------------*/

.iq-profile-avatar {
  position: relative;
}

.iq-profile-avatar:before {
  content: "";
  position: absolute;
  border-radius: 100%;
  z-index: 1;
  border: 2px solid #fff;
  background-color: #374948;
  height: 16px;
  width: 16px;
  right: 0;
  bottom: 3px;
}

.iq-profile-avatar.status-online:before {
  background-color: #0abb78;
}

.iq-profile-avatar.status-away:before {
  background-color: #ffb820;
}

.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 15px 0 0;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid #777D74;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: "FontAwesome";
  color: #374948;
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/*---------------------------------------------------------------------
                                  Countdown
-----------------------------------------------------------------------*/

.iq-comingsoon {
  margin-top: 80px;
}

.countdown {
  margin: 60px 0 0;
  padding: 0;
}

.countdown li {
  margin: 0 30px 0 0;
  padding: 45px 0;
  list-style: none;
  width: 22.8%;
  display: inline-block;
  text-align: center;
  line-height: normal;
  background: #ffffff;
  float: left;
  color: #374948;
  border-radius: 15px;
}

.countdown li span {
  font-size: 60px;
  display: inline-block;
  width: 100%;
  color: var(--iq-primary);
}

.countdown li:last-child {
  margin-right: 0;
}

.iq-comingsoon-form {
  position: relative;
}

.iq-comingsoon-form button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 11px 30px;
}

/*---------------------------------------------------------------------
                                  Progressbar
-----------------------------------------------------------------------*/

.iq-progress-bar {
  background: #eef1f7;
  box-shadow: 0 0 0;
  height: 6px;
  margin: 0;
  position: relative;
  width: 100%;
  border-radius: 4px;
}

.iq-progress-bar > span {
  background: var(--iq-primary) none repeat scroll 0 0;
  display: block;
  height: 100%;
  width: 0;
  border-radius: 4px;
}

/*---------------------------------------------------------------------
                             Dropdown Menu
-----------------------------------------------------------------------*/

.iq-card-header .dropdown-bg {
  background: #e0defc;
  padding: 8px 15px;
  color: var(--iq-primary);
  border-radius: 15px;
}

.iq-card-header .dropdown-menu {
  margin: 0;
  padding: 0;
  font-size: 13px;
  top: 20px !important;
  border-radius: 10px;
  border-color: #eef0f4;
  -webkit-box-shadow: 0px 15px 30px 0px rgb(233, 237, 244);
  -moz-box-shadow: 0px 15px 30px 0px rgb(233, 237, 244);
  box-shadow: 0px 15px 30px 0px rgb(233, 237, 244);
}

.iq-card-header .dropdown-menu .dropdown-item {
  padding: 8px 15px;
  color: #777D74;
}

.iq-card-header .dropdown-menu .dropdown-item i {
  font-size: 14px;
  vertical-align: middle;
}

.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:hover {
  background: transparent;
  color: var(--iq-primary);
}

/*--------------*/

.iq-card-header select.form-control {
  background: #eef0f4;
  border: none;
  font-size: 14px;
  margin-right: 15px;
}

.iq-card-header select.form-control.width-100 {
  width: 180px;
}

/*---------------------------------------------------------------------
                             Badge Img Tag
-----------------------------------------------------------------------*/

.badge-img-tag {
  position: absolute;
  top: 15px;
  left: 15px;
}

/*---------------------------------------------------------------------
                             list Style 1
-----------------------------------------------------------------------*/

.iq-list-style-1 {
  margin: 0;
  padding: 0;
}

.iq-list-style-1 li {
  display: inline-block;
  float: left;
  list-style-type: none;
  margin-right: 15px;
}

.iq-list-style-1 li:last-child {
  margin-right: 0;
}

.iq-list-style-1 li a {
  color: #777D74;
}

.iq-list-style-1 li a i {
  color: #9597af;
  margin-right: 5px;
}

.iq-list-style-1 li a:hover,
.iq-list-style-1 li a:focus {
  text-decoration: none;
  color: var(--iq-primary);
}

.iq-list-style-1.text-white li a,
.iq-list-style-1.text-white li a i {
  color: #ffffff;
}

.list-inline.text-white li a {
  color: #ffffff;
}

.list-inline.text-white li a:hover {
  opacity: 0.6;
  text-decoration: none;
}

/*---------------------------------------------------------------------
                             Icon Box
-----------------------------------------------------------------------*/

.iq-icon-box {
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  margin-right: 10px;
  font-size: 22px;
  text-decoration: none;
}

.iq-icon-box:hover {
  text-decoration: none;
}

/*---------------------------------------------------------------------
                             Tasks Card
-----------------------------------------------------------------------*/

.tasks-card {
  padding: 0 0 15px 0;
  border-bottom: 1px solid #eef0f4;
  margin: 0 0 15px 0;
}

.tasks-card .close {
  font-size: 16px;
}

.tasks-card:last-child {
  border: none;
  margin: 0;
  padding: 0;
}

/*---------------------------------------------------------------------
                               Sign In
-----------------------------------------------------------------------*/

.sign-in-page {
  height: 100vh;
}

.sign-in-logo {
  display: inline-block;
  width: 100%;
}

.sign-in-logo img {
  height: 50px;
}

.sign-in-from {
  padding: 40px 60px;
}

.sign-info {
  border-top: 1px solid #cdd1f3;
  margin-top: 30px;
  padding-top: 20px;
}

/*---------------------------------------------------------------------
                               Dash Hover
-----------------------------------------------------------------------*/

.dash-hover-blank {
  background-color: #eee;
  border: dashed 2px #444;
  height: 90%;
  justify-content: center;
}

.dash-hover-blank:hover {
  cursor: pointer;
  background-color: #ddd;
}

/*---------------------------------------------------------------------
                               Waves Effect
-----------------------------------------------------------------------*/

.iq-waves-effect {
  position: relative;
  overflow: hidden;
}

/*  Ripple */

.ripple {
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(71, 92, 199, 0.1);
  transform: scale(0);
  position: absolute;
  opacity: 1;
}

.rippleEffect {
  animation: rippleDrop 0.6s linear;
}

@keyframes rippleDrop {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/*---------------------------------------------------------------------
                                Sidebar
-----------------------------------------------------------------------*/

.iq-sidebar {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 260px;
  height: 100%;
  background: #ffffff;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  -moz-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
}

.iq-sidebar .iq-sidebar-logo {
  float: none;
  width: 100%;
}

.iq-sidebar #sidebar-scrollbar {
  margin-top: 15px;
}

/*---------------------------------------------------------------------
                             Sidebar Menu
-----------------------------------------------------------------------*/

/* Form */

#sidebar-scrollbar {
  height: 100vh;
}

.scroll-content div.p-3 {
  height: 100px;
}

.iq-sidebar-menu .iq-menu .iq-menu-title {
  display: block;
  color: #374948;
  font-size: 15px;
  position: relative;
  padding: 15px;
  font-weight: 500;
  line-height: 18px;
}

.iq-sidebar-menu .iq-menu .iq-menu-title i {
  display: none;
}

.iq-sidebar-menu {
  position: relative;
}

.iq-sidebar-menu .iq-menu {
  margin: 0 15px;
  padding: 0;
  list-style-type: none;
}

.iq-sidebar-menu .iq-menu li {
  list-style-type: none;
  position: relative;
  white-space: nowrap;
}

.iq-sidebar-menu .iq-menu > li {
  margin: 10px 0;
}

.iq-sidebar-menu .iq-menu li a {
  font-size: 14px;
  white-space: nowrap;
  display: block;
  color: #777D74;
  position: relative;
  padding: 15px;
  line-height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  border-radius: 0;
}

.iq-sidebar-menu .iq-menu li li a {
  font-size: 14px;
}

.iq-sidebar-menu .iq-menu li a i {
  font-size: 20px;
  margin-right: 10px;
  vertical-align: middle;
  width: 20px;
  display: inline-block;
  float: left;
}

.iq-sidebar-menu .iq-menu li li a i {
  font-size: 16px;
}

.iq-sidebar-menu .iq-menu li a span {
  white-space: nowrap;
  padding: 0;
  display: inline-block;
  float: left;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  transition: none;
}

.iq-sidebar-menu .iq-menu li a .iq-arrow-right {
  margin-right: 0;
  margin-left: auto;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.iq-sidebar-menu .iq-menu li a .badge {
  position: absolute;
  right: 15px;
  -webkit-transition: all 0.9s ease-out 0s;
  -moz-transition: all 0.9s ease-out 0s;
  -ms-transition: all 0.9s ease-out 0s;
  -o-transition: all 0.9s ease-out 0s;
  transition: all 0.9s ease-out 0s;
}

.iq-sidebar-menu .iq-menu li.active {
  border-bottom: none;
  margin-bottom: 0px;
}

.iq-sidebar-menu .iq-menu li a:hover {
  color: var(--iq-primary);
}

.iq-sidebar-menu .iq-menu li.active > a {
  color: #ffffff;
  text-decoration: none;
  background: var(--iq-primary);
  background: -moz-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, var(--iq-primary)), color-stop(100%, var(--iq-primary-light)));
  background: -webkit-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: -o-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: -ms-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="var(--iq-primary)", endColorstr="#b47af3", GradientType=1);
  text-decoration: none;
}

.iq-search-bar .searchbox .search-link {
  position: absolute;
  right: 15px;
  top: 2px;
  font-size: 16px;
}

.iq-sidebar-menu .iq-menu li a[aria-expanded=true] {
  color: #ffffff;
  text-decoration: none;
  border-radius: 15px 15px 0 0;
  background: var(--iq-primary);
  background: -moz-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, var(--iq-primary)), color-stop(100%, var(--iq-primary-light)));
  background: -webkit-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: -o-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: -ms-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="var(--iq-primary)", endColorstr="#b47af3", GradientType=1);
  transition: none;
  border-radius: 0 15px 0 0;
}

.iq-sidebar-menu .iq-menu li a[aria-expanded=true] .iq-arrow-right {
  transform: rotate(90deg);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.iq-sidebar-menu .iq-menu li .iq-submenu li a:hover,
.iq-sidebar-menu .iq-menu li.active .iq-submenu li > a {
  background: transparent;
}

.iq-sidebar-menu .iq-menu li.active .iq-submenu li.active > a {
  background: #e0defc;
  color: var(--iq-primary);
  border-radius: 0;
}

.iq-sidebar-menu .iq-menu li.active .iq-submenu li:last-child > a {
  border-radius: 0 0 10px 10px;
}

.iq-sidebar-menu .iq-menu li .iq-submenu li > a::before {
  display: none;
}

body.sidebar-main .iq-sidebar .iq-menu li a[aria-expanded=false].collapsed {
  border-radius: 15px;
}

body.sidebar-main .iq-sidebar .iq-menu li a[aria-expanded=true] {
  border-radius: 15px 15px 0 0;
}

.iq-sidebar-menu .iq-menu li ul {
  /* display: none;*/
  padding-left: 0px;
}

.iq-sidebar-menu .iq-menu li ul li a {
  color: #777D74;
  padding: 12px 20px;
}

.iq-sidebar-menu .iq-menu li ul a:hover {
  color: var(--iq-primary);
  text-decoration: none;
  background: transparent;
}

.iq-sidebar-menu .iq-menu .iq-submenu {
  background: #efeefd;
  border-radius: 0 0 15px 15px;
}

.iq-sidebar-menu .iq-menu li ul.iq-submenu-data li > a {
  padding-left: 50px;
}

.iq-sidebar-menu .iq-menu li.active .iq-submenu li a[aria-expanded=true],
.iq-sidebar-menu .iq-menu li .iq-submenu li a[aria-expanded=true] {
  background: #968FF7;
  border-radius: 0;
  color: #ffffff;
}

.iq-sidebar-menu .iq-menu li.active ul.iq-submenu-data li.active > a {
  background: #d5d2fe;
  color: var(--iq-primary);
  border-radius: 0;
}

.iq-sidebar-menu .iq-menu li ul.iq-submenu-data li {
  margin: 0;
}

body.sidebar-main .iq-sidebar .iq-sidebar-menu .iq-menu li ul.iq-submenu-data li > a {
  padding-left: 15px;
}

body.sidebar-main .iq-sidebar:hover .iq-sidebar-menu .iq-menu li ul.iq-submenu-data li > a {
  padding-left: 50px;
}

/*---------Sidebar Dark---------------*/

.iq-sidebar.sidebar-dark,
.iq-sidebar.sidebar-dark .iq-sidebar-logo {
  background: #151b21;
  color: #ffffff;
}

.iq-sidebar.sidebar-dark .iq-sidebar-logo a span {
  color: rgba(255, 255, 255, 0.8);
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu .iq-menu-title {
  color: rgba(255, 255, 255, 0.4);
}

.iq-sidebar.sidebar-dark .iq-menu li > a::before {
  display: none;
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li > a:hover,
.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li.active > a,
.iq-sidebar.sidebar-dark .iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li a {
  color: #ffffff;
  background: #0f1317;
}

.iq-sidebar.sidebar-dark .iq-menu li a:hover,
.iq-sidebar.sidebar-dark .iq-menu li.active > a,
.iq-sidebar.sidebar-dark .iq-menu li.menu-open > a {
  color: #ffffff;
  background: #0f1317;
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li a {
  color: rgba(255, 255, 255, 0.6);
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li ul li a {
  background: transparent;
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li ul {
  padding-left: 0;
  background: #1e242a;
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li.active .iq-submenu li > a {
  background: transparent;
}

.iq-sidebar.sidebar-dark .iq-menu li.active .iq-submenu li.active > a {
  background: #1e1e1e;
  color: #fff;
}

.iq-sidebar.sidebar-dark .ripple {
  background: rgba(255, 255, 255, 0.1);
}

.iq-sidebar.sidebar-dark .iq-menu-bt {
  background: #0f1317;
}

.iq-sidebar.sidebar-dark .line-menu {
  background: #ffffff;
}

.menu-open {
  display: block !important;
}

.menu-open > a {
  text-decoration: none;
}

.sidebar-main .iq-sidebar .iq-submenu li a {
  font-size: 14px;
  padding: 12px 20px;
}

/*---------------------------------------------------------------------
                             Top Menu
-----------------------------------------------------------------------*/

.iq-top-navbar {
  border-radius: 0 0 15px 15px;
  padding: 0;
  min-height: 73px;
  position: fixed;
  top: 0;
  left: auto;
  right: 30px;
  width: calc(100% - 319px);
  display: inline-block;
  z-index: 99;
  background: #efeefd;
  margin: 0;
  transition: all 0.45s ease 0s;
}

.iq-navbar-custom {
  background: var(--iq-color-white);
  border-radius: 15px;
  margin: 20px 0 0;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  -moz-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
}

.iq-top-navbar.fixed-header {
  margin: 0;
  right: 0;
  width: calc(100% - 260px);
  border-radius: 0;
  transition: all 0.45s ease 0s;
}

.iq-top-navbar .iq-navbar-custom .navbar-breadcrumb {
  display: block;
  margin-left: 30px;
  padding: 10px 0;
}

.iq-top-navbar .breadcrumb .breadcrumb-item.active {
  color: #777D74;
}

.iq-top-navbar .breadcrumb {
  background: transparent;
  padding: 0;
  margin: 0;
}

.iq-top-navbar .breadcrumb li {
  list-style-type: none;
  font-size: 12px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "\ea5f";
  font-family: "remixicon";
  font-size: 14px;
  vertical-align: sub;
  color: #777D74;
}

.iq-top-navbar .breadcrumb li a,
.iq-top-navbar .breadcrumb li a:hover {
  text-decoration: none;
}

.navbar-list {
  margin: 0;
  padding: 0;
  float: right;
}

.navbar-list li {
  float: left;
  list-style-type: none;
  position: relative;
}

.navbar-list li > .dropdown .dropdown-menu {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.navbar-list li .dropdown-toggle::after {
  display: none;
}

.navbar-list li > a.language-title {
  font-size: 16px;
}

.navbar-list li > a {
  font-size: 18px;
  padding: 0 15px;
  line-height: 73px;
  color: var(--iq-primary);
  display: block;
  min-height: 75px;
}

.navbar-list li:last-child > a {
  padding-right: 20px;
}

.menu-tag {
  position: absolute;
  background: #374948;
  color: #ffffff;
  display: inline-block;
  top: 18px;
  left: 18px;
  font-size: 12px;
  border-radius: 90px;
  line-height: normal;
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  display: inline-block;
}

.navbar-list li a:hover {
  text-decoration: none;
}

.navbar-list li > a > img {
  width: 50px;
  height: 50px;
}

.navbar-list li.iq-nav-user {
  display: flex;
  align-items: flex-start;
}

.navbar-list li.iq-nav-user span {
  flex: 1;
  font-size: 14px;
}

.iq-top-navbar .iq-sidebar-logo .top-logo {
  display: none;
}

.iq-breadcrumb {
  display: none;
}

.nav-item span.dots {
  height: 5px;
  width: 5px;
  font-size: 0;
  text-align: center;
  padding: 0;
  position: absolute;
  top: 25px;
  right: 10px;
  animation: shadow-pulse-dots 1s infinite;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}

.nav-item span.count-mail {
  height: 5px;
  width: 5px;
  font-size: 10px;
  text-align: center;
  line-height: 15px;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 10px;
  animation: shadow-pulse 1s infinite;
  border-radius: 50%;
}

@keyframes shadow-pulse-dots {
  0% {
    box-shadow: 0 0 0 0px rgba(230, 65, 65, 0.2);
  }

  100% {
    box-shadow: 0 0 0 15px rgba(230, 65, 65, 0);
  }
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 132, 255, 0.2);
  }

  100% {
    box-shadow: 0 0 0 15px rgba(0, 132, 255, 0);
  }
}

div#lottie-beil,
div#lottie-mail,
div#lottie-fullscreen {
  height: 20px;
  width: 20px;
}

div#lottie-beil svg path,
div#lottie-mail svg path,
div#lottie-fullscreen svg path {
  stroke: var(--iq-primary);
}

.navbar-list li .caption {
  line-height: 16px;
}

.top-nav-bottom {
  margin-left: 260px;
  border-top: 1px solid #f2edff;
}

.topnav-toolbar li a {
  display: block;
  padding: 10px 15px;
  text-transform: capitalize;
  color: var(--iq-secondary);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}

.topnav-toolbar li.date-today {
  background: #f2f3f7;
}

.topnav-toolbar li.active a {
  background: #edf3ff;
  color: var(--iq-primary);
}

.topnav-toolbar ul li {
  list-style: none;
  padding: 13px 0;
}

.navbar-list li > .dropdown {
  line-height: 73px;
  cursor: pointer;
}

.iq-search-bar {
  padding: 0 15px;
}

.iq-search-bar .searchbox {
  width: 480px;
  position: relative;
}

.iq-search-bar .search-input {
  width: 100%;
  padding: 5px 40px 5px 15px;
  border-radius: 10px;
  border: none;
  background: #efeefd;
}

/*---------- Sidebar Logo --------------*/

.iq-sidebar-logo {
  display: none;
  width: 260px;
  padding-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  float: left;
  position: relative;
  background: #ffffff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background: transparent;
}

.iq-sidebar-logo a {
  display: flex;
  align-items: flex-start;
}

.iq-sidebar-logo a span {
  line-height: 45px;
  margin-left: 10px;
  font-size: 28px;
  color: #374948;
  font-family: "Roboto", sans-serif;
  text-transform: lowercase;
  flex: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.iq-sidebar-logo a:hover,
.iq-sidebar-logo a:focus {
  text-decoration: none;
}

.iq-top-navbar .iq-navbar-custom .iq-menu-bt-sidebar .iq-menu-bt {
  display: block;
  margin: 0 0 0 20px;
}

.sidebar-main .iq-menu-bt-sidebar {
  position: absolute;
  right: -59px;
}

.sidebar-main .iq-menu-bt-sidebar .iq-menu-bt {
  opacity: 1;
}

.iq-menu-bt {
  display: none;
  background: #efeefd;
  padding: 12px 7px;
  margin-right: 15px;
  margin-left: 15px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 12px;
}

.sidebar-main .iq-menu-bt-sidebar .iq-menu-bt {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.wrapper-menu {
  font-size: 30px;
  line-height: 15px;
  color: var(--iq-primary);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 330ms ease-out;
}

.line-menu {
  background-color: var(--iq-primary);
  border-radius: 90px;
  width: 100%;
  height: 2px;
}

.line-menu.half {
  width: 50%;
}

.line-menu.start {
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.37, 0.37);
  transform-origin: right;
}

.open .line-menu.start {
  transform: rotate(-90deg) translateX(3px);
}

.line-menu.end {
  align-self: flex-end;
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.37, 0.37);
  transform-origin: left;
}

.open .line-menu.end {
  transform: rotate(-90deg) translateX(-3px);
}

.hover-circle {
  opacity: 0;
  position: absolute;
  top: 0;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
}

.wrapper-menu.open .hover-circle {
  opacity: 1;
}

.wrapper-menu.open .main-circle {
  opacity: 0;
}

/*----------------------*/

.iq-top-navbar .iq-sub-dropdown {
  width: 300px;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  background-color: #ffffff;
  transition: all 0.3s;
  padding: 0;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  -moz-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.iq-top-navbar .iq-sub-dropdown .iq-card-body div {
  border-radius: 10px 10px 0 0;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-12-22 15:30:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.iq-show .iq-sub-dropdown {
  display: inline-block;
}

.navbar-list li .iq-sub-dropdown .iq-sub-card {
  font-size: inherit;
  padding: 15px;
  line-height: normal;
  color: inherit;
  border-bottom: 1px solid #f3f7fd;
  display: inline-block;
  width: 100%;
}

.iq-top-navbar .search-box {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  background-color: #ffffff;
  transition: all 0.3s;
  padding: 15px;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  -moz-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.iq-show .search-box {
  display: inline-block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.search-box .search-input {
  width: 300px;
  height: 50px;
  padding: 0 1em;
  border: 1px solid #d4e3f9;
  background-color: #f3f7fd;
  opacity: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.iq-sub-dropdown .iq-card-icon {
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 22px;
}

/*---------------------------------------------------------------------
                              Content Page
-----------------------------------------------------------------------*/

.content-page {
  margin-left: 260px;
  overflow: hidden;
  padding: 120px 15px 0;
  min-height: calc(100vh - 135px);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.iq-user-dropdown {
  width: 350px;
}

body.sidebar-main .iq-top-navbar {
  width: calc(100% - 140px);
}

body.sidebar-main .iq-top-navbar.fixed-header {
  width: calc(100% - 80px);
}

body.sidebar-main .content-page,
body.sidebar-main .iq-footer {
  margin-left: 80px;
}

body.sidebar-main .iq-sidebar-logo {
  width: 100%;
  padding-right: 20px;
  padding-bottom: 20px;
  background: #edf3ff;
}

body.sidebar-main .iq-sidebar .iq-sidebar-menu .iq-menu li a span,
body.sidebar-main .iq-sidebar-logo a span,
body.sidebar-main .iq-sidebar-menu .iq-menu li a .badge {
  opacity: 0;
  display: none;
  transition: all 0.45s ease 0s;
}

body.sidebar-main .iq-sidebar-menu .iq-menu .iq-menu-title span {
  display: none;
}

body.sidebar-main .iq-sidebar-menu .iq-menu .iq-menu-title i {
  font-size: 20px;
  display: block;
}

body.sidebar-main .iq-sidebar {
  width: 80px;
}

body.sidebar-main .iq-sidebar-menu .iq-menu li a .iq-arrow-right {
  margin-right: 0;
  display: none;
}

body.sidebar-main .iq-sidebar:hover,
body.sidebar-main .iq-sidebar:hover .iq-sidebar-logo {
  width: 260px;
}

body.sidebar-main .iq-sidebar:hover .iq-sidebar-menu .iq-menu li a .iq-arrow-right {
  margin-right: 0;
  display: inline-block;
}

body.sidebar-main .iq-sidebar:hover .iq-sidebar-menu .iq-menu li a span,
body.sidebar-main .iq-sidebar:hover .iq-sidebar-logo a span,
body.sidebar-main .iq-sidebar:hover .iq-sidebar-menu .iq-menu li a .badge,
body.sidebar-main .iq-sidebar:hover .iq-menu-bt {
  opacity: 1;
  display: inline-block;
  transition: all 0.45s ease 0s;
}

body.sidebar-main .iq-sidebar .iq-sidebar-menu .iq-menu li ul {
  display: none;
}

body.sidebar-main .iq-sidebar .iq-sidebar-menu .iq-menu li ul.show {
  display: block;
}

body.sidebar-main .iq-sidebar.sidebar-hover .iq-sidebar-menu .iq-menu li ul {
  display: none;
}

/*--------------------*/

.iq-card-header-list {
  margin: 0;
  padding: 0;
  float: right;
}

.iq-card-header-list li {
  list-style-type: none;
  float: left;
  display: inline-block;
  font-size: 20px;
  margin: 0 0 0 8px;
}

.iq-card-header-list li a,
.iq-card-header-list li a:hover {
  text-decoration: none;
}

/*-------------- Media Header ---------------*/

.iq-card .iq-card-header.iq-header-media {
  padding: 20px;
}

/*-------------- Content Page ---------------*/

.big-card {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #f2edff;
  padding: 20px;
}

/*---------------------------------------------------------------------
                              Error Page
-----------------------------------------------------------------------*/

.iq-error {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: inline-block;
}

.iq-error h1 {
  font-weight: 900;
  font-size: 16rem;
  line-height: 14rem;
  margin-bottom: 0;
  padding-top: 45px;
  letter-spacing: 15px;
}

.iq-error-img {
  position: static;
  display: inline-block;
  width: 700px;
  margin: 100px 0 50px;
}

.error-500 .iq-error-img {
  width: 100%;
}

/*---------------------------------------------------------------------
                               OWL Carousel
-----------------------------------------------------------------------*/

.owl-carousel .owl-nav {
  display: block;
  position: absolute;
  text-indent: inherit;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  left: 0;
  width: 100%;
  cursor: pointer;
  z-index: 999;
}

.owl-carousel .owl-nav .owl-prev {
  display: block;
  position: absolute;
  text-align: center;
  text-indent: inherit;
  left: -8%;
  width: auto;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  -moz-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  -ms-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
}

.owl-carousel .owl-nav .owl-next {
  display: block;
  position: absolute;
  text-align: center;
  text-indent: inherit;
  right: -8%;
  width: auto;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  -moz-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  -ms-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
}

.owl-carousel .owl-nav i {
  font-size: 24px;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  line-height: 42px;
  padding-left: 0px;
  display: inline-block;
  color: #fff;
  background: #e7e7e7;
  font-weight: normal;
  text-align: center;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.owl-carousel .owl-nav i:hover {
  background: #4ac4f3;
  color: #fff;
}

/* Dots */

.owl-carousel .owl-controls .owl-dot {
  margin-top: 20px;
  display: inline-block;
}

.owl-carousel .owl-dots {
  position: relative;
  width: 100%;
  display: inline-block;
  text-indent: inherit;
  text-align: center;
  cursor: pointer;
}

.owl-carousel .owl-dots .owl-dot span {
  background: #ffffff;
  display: inline-block;
  border-radius: 30px;
  margin: 0px 5px;
  height: 8px;
  width: 8px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.owl-carousel .owl-dots .owl-dot:hover,
.owl-carousel .owl-dots .owl-dot.active {
  outline: none;
  border: none;
}

.owl-carousel .owl-dots .owl-dot:hover span {
  background: #ffffff;
}

.owl-carousel .owl-dots .owl-dot.active span {
  background: #ffffff;
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.2);
}

.iq-example-row .row > .col,
.iq-example-row .row > [class^=col-] {
  padding-top: 0.75rem;
  color: #374948;
  padding-bottom: 0.75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}

.iq-example-row-flex-cols .row {
  margin-bottom: 15px;
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.iq-icons-list {
  line-height: 50px;
  margin-bottom: 15px;
  display: inline-block;
}

.iq-icons-list i {
  text-align: center;
  vertical-align: middle;
  font-size: 22px;
  float: left;
  width: 40px;
  height: 50px;
  line-height: 50px;
  margin-right: 10px;
  display: inline-block;
}

.iq-icons-list .icon {
  text-align: center;
  vertical-align: middle;
  font-size: 22px;
  width: 40px;
  height: 40px;
  line-height: 50px;
  margin-right: 10px;
  display: inline-block;
}

.iq-icons-list span {
  display: inline-block;
  vertical-align: middle;
}

.iq-card-icon {
  height: 60px;
  width: 60px;
  display: inline-block;
  line-height: 60px;
  text-align: center;
  font-size: 22px;
}

/*---------------------------------------------------------------------
                               Timeline
-----------------------------------------------------------------------*/

.iq-timeline {
  margin: 0 0 0 5px;
  padding: 0;
  width: 100%;
  border-left: 3px solid #f2edff;
}

.iq-timeline li {
  margin-left: 15px;
  position: relative;
  padding: 15px 15px 0 5px;
  list-style-type: none;
}

.iq-timeline li .timeline-dots {
  position: absolute;
  top: 20px;
  left: -24px;
  border: 3px solid var(--iq-primary);
  border-radius: 90px;
  padding: 5px;
  background: #fff;
}

/*---------------------------------------------------------------------
                               Team
-----------------------------------------------------------------------*/

.iq-team {
  background: #ffffff;
  padding: 15px;
  position: relative;
}

.team-authors-bg {
  position: relative;
  margin-bottom: 50px;
}

.team-authors-bg .avatar-80 {
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

/*------------------------------*/

.iq-team-1 {
  position: relative;
  background: #ffffff;
}

.iq-team-1 .iq-team-1-info {
  position: absolute;
  padding: 30px 15px;
  bottom: 0;
  left: 0;
  width: 100%;
}

/*---------------------------------------------------------------------
                           User Add Block
-----------------------------------------------------------------------*/

.user-add-block {
  background: #ffffff;
}

.user-add-plus {
  position: absolute;
  right: 30px;
  font-size: 24px;
  bottom: -25px;
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  background: #374948;
  color: #ffffff;
  border-radius: 900px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(57, 60, 82, 0.1);
  -moz-box-shadow: 0px 10px 30px 0px rgba(57, 60, 82, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(57, 60, 82, 0.1);
}

.user-add-plus:hover {
  background: var(--iq-primary);
  color: #ffffff;
  text-decoration: none;
}

.user-add-authors {
  position: relative;
}

.user-add-authors:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 132, 255, 0.8);
}

.user-add-authors .user-add-media {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  display: inline-block;
}

.user-add-list {
  padding: 30px;
}

/*---------------------------------------------------------------------
                             Email list
-----------------------------------------------------------------------*/

.iq-email-list .iq-email-ui {
  margin: 0;
  padding: 0;
}

.iq-email-list .iq-email-ui li {
  list-style-type: none;
  font-size: 16px;
  color: #777D74;
  margin-bottom: 3px;
}

.iq-email-list .iq-email-ui li:last-child {
  margin-bottom: 0;
}

.iq-email-list .iq-email-ui li a {
  color: #777D74;
  padding: 0;
  display: inline-block;
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.iq-email-list .iq-email-ui li.active a,
.iq-email-list .iq-email-ui li a:hover {
  text-decoration: none;
  color: var(--iq-primary);
}

.iq-email-list .iq-email-ui li {
  list-style-type: none;
}

.iq-email-list .iq-email-ui li i {
  margin-right: 10px;
}

.iq-email-list .iq-email-ui li .badge {
  float: right;
  font-weight: normal;
  margin-top: 3px;
}

.iq-email-list .iq-email-ui.iq-email-label li {
  margin-bottom: 10px;
}

.iq-email-list .iq-email-ui.iq-email-label li:last-child {
  margin-bottom: 0;
}

/*----------------------------------*/

.iq-email-to-list ul {
  margin: 0;
  padding: 0;
}

.iq-email-to-list ul li {
  list-style-type: none;
  font-size: 20px;
  color: #777D74;
  margin: 0 5px 0 0;
  float: left;
}

.iq-email-to-list ul li:first-child a {
  background: none;
  width: auto;
}

.iq-email-to-list ul li > a {
  background: #edf3ff;
  color: var(--iq-primary);
  text-align: center;
  line-height: 38px;
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  background: rgba(130, 122, 243, 0.2);
  background: -moz-linear-gradient(left, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(130, 122, 243, 0.2)), color-stop(100%, rgba(180, 122, 243, 0.2)));
  background: -webkit-linear-gradient(left, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  background: -o-linear-gradient(left, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  background: -ms-linear-gradient(left, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  background: linear-gradient(to right, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="var(--iq-primary)", endColorstr="#b47af3", GradientType=1);
}

.iq-email-to-list ul li a:hover,
.iq-email-to-list ul li.q-list a:hover {
  color: var(--iq-primary);
  text-decoration: none;
}

.iq-email-to-list ul li .custom-control-label::before {
  top: 13px;
  left: -20px;
}

/*----------------------------------*/

.iq-email-search {
  position: relative;
}

.iq-email-search .form-control {
  height: 40px;
  border: 1px solid #eef0f4;
  width: 300px;
}

.iq-email-search .search-link {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 18px;
  line-height: 38px;
  color: var(--iq-primary);
}

.iq-email-search .search-link:hover {
  text-decoration: none;
}

/*----------------------------------*/

.iq-email-listbox .iq-email-sender-list {
  padding: 0;
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.iq-email-listbox .iq-email-sender-list > li {
  list-style-type: none;
  position: relative;
  padding: 15px 0;
  border-top: 1px solid #eef0f4;
  float: left;
  display: inline-block;
  width: 100%;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.iq-email-listbox .iq-email-sender-list > li:first-child {
  border: 0;
}

.iq-email-listbox .iq-email-sender-list > li:hover {
  background: #fff;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(71, 91, 199, 0.2);
  -moz-box-shadow: 0px 0px 30px 0px rgba(71, 91, 199, 0.2);
  box-shadow: 0px 0px 30px 0px rgba(71, 91, 199, 0.2);
}

.iq-email-listbox .iq-email-sender-list li a:hover {
  text-decoration: none;
}

.iq-email-listbox .iq-email-sender-list li.iq-unread a {
  color: var(--iq-primary);
  font-weight: 500;
}

.iq-email-listbox .iq-email-sender-list li.iq-unread li a {
  color: #007bff;
}

.iq-email-sender-info .iq-checkbox-mail,
.iq-email-sender-info .iq-star-toggle {
  display: block;
  float: left;
}

.iq-email-sender-info .iq-checkbox-mail {
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-top: 4px;
}

.iq-email-sender-info .iq-star-toggle {
  opacity: 0.25;
  color: #777D74;
  margin-left: 10px;
  font-size: 18px;
  height: 32px;
}

.iq-email-sender-info .iq-star-toggle .attachment-available {
  opacity: 1;
}

.iq-email-sender-info .iq-email-title {
  color: #777D74;
  position: absolute;
  top: 0;
  left: 85px;
  right: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
  line-height: 62px;
}

.iq-email-content {
  position: absolute;
  top: 0;
  left: 300px;
  right: 0;
  bottom: 0;
  line-height: 62px;
}

.iq-email-content .iq-email-date,
.iq-email-content .iq-email-subject {
  position: absolute;
  top: 0;
}

.iq-email-content .iq-email-subject {
  color: #777D74;
  left: 0;
  right: 420px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.iq-email-content .iq-email-date {
  opacity: 0.5;
  right: 20px;
  width: 150px;
  text-align: right;
  padding-left: 10px;
  color: #374948;
}

.iq-email-content .iq-email-badges {
  position: absolute;
  top: 0;
  right: 180px;
  width: 170px;
  text-align: center;
  padding-left: 10px;
  color: #374948;
}

.iq-email-content .iq-email-badges > span {
  margin-left: 10px;
}

.iq-email-content .iq-email-parent {
  position: absolute;
  top: 0;
  right: 350px;
  width: 160px;
  text-align: center;
  padding-left: 10px;
  color: #374948;
}

.iq-email-sender-list .iq-social-media {
  position: absolute;
  line-height: 62px;
  right: -200px;
  top: 0;
  background: #ffffff;
  font-size: 18px;
  padding: 0 30px;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.iq-email-listbox .iq-email-sender-list li:hover .iq-social-media {
  right: 0;
}

/*----------------------------------*/

.iq-inbox-details tr td {
  padding: 5px 10px;
}

.iq-inbox-details tr td:first-child {
  width: 75px;
  color: #374948;
}

.iq-subject-info {
  display: flex;
  align-items: flex-start;
}

.iq-subject-status {
  margin-left: 15px;
  flex: 1;
}

/*---------------------------------------------------------------------
                             Booking list
-----------------------------------------------------------------------*/

.iq-booking-date {
  background: #eef0f4;
  padding: 15px 15px;
  margin: 0 15px 45px;
  outline: none;
  position: relative;
  overflow: hidden;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: block;
}

.iq-booking-date:hover {
  text-decoration: none;
}

.iq-booking-date:after {
  content: "";
  position: absolute;
  bottom: 20px;
  left: -8px;
  height: 16px;
  width: 16px;
  background: #ffffff;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
  opacity: 0;
}

.iq-booking-date:before {
  content: "";
  position: absolute;
  bottom: 20px;
  right: -8px;
  height: 16px;
  width: 16px;
  background: #ffffff;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
  opacity: 0;
}

.slick-slide.slick-active.slick-center .iq-booking-date {
  background: var(--iq-primary);
  color: #ffffff;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 132, 255, 0.4);
  -moz-box-shadow: 0px 10px 30px 0px rgba(0, 132, 255, 0.4);
  box-shadow: 0px 10px 30px 0px rgba(0, 132, 255, 0.4);
}

.slick-slide.slick-active.slick-center .iq-booking-date h3 {
  color: #ffffff;
}

.slick-slide.slick-active.slick-center .iq-booking-date:after {
  opacity: 1;
}

.slick-slide.slick-active.slick-center .iq-booking-date:before {
  opacity: 1;
}

.slick-initialized .slick-slide {
  outline: none;
}

.iq-booking-date .iq-booking-dot {
  height: 12px;
  width: 12px;
  margin-bottom: 10px;
  display: inline-block;
  background: #ffffff;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
}

.iq-booking-date h3 {
  line-height: 1.5rem;
  margin-bottom: 0;
}

.iq-booking-date h3 small {
  font-size: 14px;
}

.icon-tag-box span {
  display: inline-block;
  width: 100%;
  font-size: 16px;
}

.iq-booking-offer-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 110%;
}

.iq-booking-offer {
  padding: 60px 100px;
  border-radius: 10px;
  margin-bottom: 30px;
}

/*----------------------------------------------------------------------
                        Chart
 ----------------------------------------------------------------------*/

#sda1-chart,
#sda1-chart1,
#sda1-chart2 {
  min-height: 200px !important;
}

/*-----------------------------------------------------------------
                        Material Card Style1
----------------------------------------------------------------- */

.card-style1 {
  display: flex;
}

.card-style1 img {
  object-fit: cover;
  border-radius: 100px;
  height: 75px;
  margin-right: 10px;
}

.card-style1 .title {
  text-transform: capitalize;
  font-weight: bold;
}

.card-style1 .percentage {
  font-weight: bolder;
  font-size: 1.5rem;
  margin: auto;
}

.card-style1 .percentage span {
  font-weight: normal;
}

/*-----------------------------------------------------------------
                        Material Card Style2
----------------------------------------------------------------- */

.card-style1.card-style2 .iq-details {
  width: 100%;
}

.card-style1.card-style2 .percentage {
  font-weight: bolder;
  font-size: 1.2rem;
  margin-top: -5px;
  margin-left: -50px;
}

/*-----------------------------------------------------------------
                        Circle Progressbar
------------------------------------------------------------------- */

.iq-circle-progressbar .percent {
  font-size: 1.3rem !important;
}

.iq-circle-progressbar svg {
  width: 100px !important;
  height: 100px !important;
}

.iq-circle-progressbar {
  margin: 0 auto;
  width: 100px !important;
  height: 100px !important;
}

/*-----------------------------------------------------------------
                        Tikit Booking List
------------------------------------------------------------------- */

.iq-booking-ab {
  display: inline-block;
}

.iq-booking-ab li {
  line-height: 3rem;
  height: 45px;
  margin-bottom: 1rem;
  position: relative;
}

.iq-booking-ab li.friest-booking-blank {
  margin-bottom: 0;
  height: 40px;
}

.iq-booking-ab li.secondary-booking-blank {
  height: 70px;
}

.iq-booking-ab li.three-booking-blank {
  height: 70px;
}

.iq-booking-no li {
  width: 4.9%;
  text-align: center;
  margin-right: 0 !important;
}

.iq-booking-no li .iq-seat {
  background: #f3f7fd;
  width: 45px;
  height: 45px;
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
}

.iq-booking-no li .iq-seat img {
  width: 100%;
}

.iq-booking-no li .iq-seat img.active-seat {
  display: none;
}

.iq-booking-no li .iq-seat.bg-secondary img.default,
.iq-booking-no li .iq-seat.active img.default {
  display: none;
}

.iq-booking-no li .iq-seat.bg-secondary img.active-seat,
.iq-booking-no li .iq-seat.active img.active-seat {
  display: inline-block;
}

.iq-booking-no li .iq-seat.active {
  background: #27b345;
}

.iq-booking-status {
  line-height: 30px;
  display: inline-block;
  margin-right: 30px;
}

.iq-booking-status span {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: inline-block;
  background: #f3f7fd;
  margin-right: 10px;
  margin-top: 6px;
  float: left;
}

/*-----------------------------------------------------------------
                        Right Sidebar Menu
------------------------------------------------------------------- */

#right-sidebar-scrollbar {
  height: 100vh;
}

.iq-sidebar-right-menu {
  position: fixed;
  top: 75px;
  right: -400px;
  width: 360px;
  background: #eef0f4;
  overflow: hidden;
  z-index: 99;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.iq-sidebar-right-menu.film-side {
  right: 0;
}

.iq-sidebar-booking,
.iq-sidebar-payment {
  padding: 30px;
}

.iq-booking-ticket {
  background: #ffffff;
  border-radius: 4px;
  padding: 30px 25px 30px;
  position: relative;
}

.iq-booking-ticket:after {
  content: "";
  position: absolute;
  bottom: 100px;
  left: -10px;
  height: 20px;
  width: 20px;
  background: var(--iq-primary);
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
}

.iq-booking-ticket:before {
  content: "";
  position: absolute;
  bottom: 100px;
  right: -10px;
  height: 20px;
  width: 20px;
  background: var(--iq-primary);
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
}

/*---------------------------------------------------------------------
FAQ
-----------------------------------------------------------------------*/

.iq-accordion.career-style.faq-style .iq-accordion-block .accordion-title span {
  padding-left: 0px;
}

.iq-accordion.career-style .iq-accordion-block {
  margin-bottom: 30px;
}

.iq-accordion.career-style.faq-style .accordion-title:before {
  display: inline-block;
  cursor: pointer;
  content: "\f218";
  font-family: "Ionicons";
  position: absolute;
  top: -8px;
  right: 15px;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
  -webkit-transition: all 0.25s ease-in-out 0s;
  -moz-transition: all 0.25s ease-in-out 0s;
  transition: all 0.25s ease-in-out 0s;
  border-radius: 4px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
}

.iq-accordion.career-style.faq-style .iq-accordion-block .accordion-details p {
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.iq-accordion.career-style .iq-accordion-block .accordion-title span {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  display: table-cell;
  color: #212529;
}

.iq-accordion.career-style.faq-style .iq-accordion-block.accordion-active .accordion-title:before {
  content: "\f209";
  display: inline-block;
}

.iq-accordion.career-style.faq-style .active-faq .row {
  background: transparent;
  padding: 10px 0px;
}

.iq-accordion.career-style.faq-style .iq-accordion-block {
  padding: 10px 5px;
  border-radius: 20px;
}

.iq-accordion.career-style.faq-style .iq-accordion-block.accordion-active .accordion-title:before,
.iq-accordion.career-style.faq-style .accordion-title:before {
  background-image: -moz-linear-gradient(0deg, rgb(109, 115, 239) 0%, rgb(163, 120, 252) 100%);
  border-radius: 90px;
}

/*---------------------------------------------------------------------
Table
-----------------------------------------------------------------------*/

table tr th img {
  width: 35px;
  height: 35px;
  border-radius: 90px;
}

.progress-animate {
  animation: progress-bar-stripes 1s linear infinite;
}

img.summary-image-top {
  width: 35px;
}

.iq-subject-info img {
  width: 80px;
}

/*---------------------------------------------------------------------
Animation Card
-----------------------------------------------------------------------*/

.animation-card {
  position: relative;
}

.animation-card .an-img {
  margin-top: -60px;
  text-align: right;
  display: inline-block;
  width: 100%;
}

.animation-card .an-img .bodymovin {
  width: 162%;
  margin-bottom: -57px;
  margin-left: -50px;
}

.animation-card .an-text {
  position: absolute;
  width: 180px;
  display: inline-block;
  top: 75px;
  left: 30px;
}

.animation-card .an-text h2 {
  margin-top: 0;
}

/*------------*/

.an-img-two {
  width: 700px;
  position: absolute;
  right: -35%;
  top: -175px;
  z-index: -1;
}

.iq-card.bg-primary.sb-top-banner-card {
  z-index: 1;
  position: relative;
}

.bodymovin {
  z-index: -1;
}

/*------------*/

.offer-an-img {
  position: absolute;
  top: -183px;
  width: 695px;
  left: -100px;
}

/*---------------------------------------------------------------------
Footer
-----------------------------------------------------------------------*/

.iq-footer {
  padding: 15px;
  background: var(--iq-card-bg);
  margin-left: 260px;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
  -moz-box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.iq-footer ul li a {
  color: var(--iq-icon-color);
}

.iq-footer ul li a:hover {
  color: var(--iq-primary);
  text-decoration: none;
}

.iq-mt--70 {
  margin-top: -70px;
}

.banner-main-text {
  position: absolute;
  left: 15%;
  bottom: 13%;
}

.nav.nav-pills.style-three .nav-link.active:before,
.nav.nav-pills.style-three .nav-link:hover:before {
  display: inline-block;
  width: 100%;
  height: 3px;
  background: var(--iq-primary);
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
}

.nav.nav-pills.style-three .nav-link:before {
  width: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  height: 3px;
  background: var(--iq-primary);
  content: "";
  position: absolute;
  right: 0;
  bottom: 0px;
}

.iq-card-body.banner-box {
  padding-bottom: 0;
}

.nav.nav-pills.style-three {
  margin-top: 10px;
}

.nav.nav-pills.style-three a:last-child {
  margin-right: 0px;
}

.nav.nav-pills.style-three a {
  background: transparent;
  position: relative;
  margin-right: 30px;
  padding: 10px 0px;
}

/*---------------------------------------------------------------------
Map Profile
-----------------------------------------------------------------------*/

.dash-tracking-icon {
  height: 80px;
}

.map-profile-card {
  margin-right: 30px;
  border: 1px solid #ddd;
  margin-bottom: 15px;
}

.track {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  overflow-x: scroll;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 20px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 20px;
}

/*---------------------------------------------------------------------
Mail Inbox
-----------------------------------------------------------------------*/

.send-panel a {
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  display: inline-block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
}

#compose-email-popup {
  position: fixed;
  max-width: 700px;
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  padding: 0 !important;
  max-height: 555px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

#compose-email-popup .iq-card {
  border-radius: 30px 30px 0 0;
}

button.close-popup {
  background: transparent;
  color: #000;
  border: none;
  font-size: 20px;
  line-height: 20px;
}

.compose-bottom {
  border-top: 1px solid #777D74;
}

.select2-container--default .select2-selection--multiple {
  border-color: #d7dbda;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  color: #374948;
  border-color: #d7dbda;
  box-shadow: none;
}

/* colors changes in radio button, checkbox, switch */

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-checkbox.custom-checkbox-color input.bg-primary ~ label::before,
.custom-checkbox.custom-checkbox-color-check input:checked.bg-primary ~ label::before,
.custom-radio.custom-radio-color input.bg-primary ~ label::before,
.custom-radio.custom-radio-color-checked input:checked.bg-primary ~ label::before,
.custom-switch.custom-switch-color input:checked.bg-primary ~ label::before {
  background-color: var(--iq-primary) !important;
  border-color: var(--iq-primary) !important;
}

.custom-checkbox.custom-checkbox-color input.bg-success ~ label::before,
.custom-checkbox.custom-checkbox-color-check input:checked.bg-success ~ label::before,
.custom-radio.custom-radio-color input.bg-success ~ label::before,
.custom-radio.custom-radio-color-checked input:checked.bg-success ~ label::before,
.custom-switch.custom-switch-color input:checked.bg-success ~ label::before {
  background-color: #27b345 !important;
  border-color: #27b345 !important;
}

.custom-checkbox.custom-checkbox-color input.bg-danger ~ label::before,
.custom-checkbox.custom-checkbox-color-check input:checked.bg-danger ~ label::before,
.custom-radio.custom-radio-color input.bg-danger ~ label::before,
.custom-radio.custom-radio-color-checked input:checked.bg-danger ~ label::before,
.custom-switch.custom-switch-color input:checked.bg-danger ~ label::before {
  background-color: #f14336 !important;
  border-color: #f14336 !important;
}

.custom-checkbox.custom-checkbox-color input.bg-warning ~ label::before,
.custom-checkbox.custom-checkbox-color-check input:checked.bg-warning ~ label::before,
.custom-radio.custom-radio-color input.bg-warning ~ label::before,
.custom-radio.custom-radio-color-checked input:checked.bg-warning ~ label::before,
.custom-switch.custom-switch-color input:checked.bg-warning ~ label::before {
  background-color: #fbc647 !important;
  border-color: #fbc647 !important;
}

.custom-checkbox.custom-checkbox-color input.bg-dark ~ label::before,
.custom-checkbox.custom-checkbox-color-check input:checked.bg-dark ~ label::before,
.custom-radio.custom-radio-color input.bg-dark ~ label::before,
.custom-radio.custom-radio-color-checked input:checked.bg-dark ~ label::before,
.custom-switch.custom-switch-color input:checked.bg-dark ~ label::before {
  background-color: #374948 !important;
  border-color: #374948 !important;
}

.custom-checkbox.custom-checkbox-color input.bg-info ~ label::before,
.custom-checkbox.custom-checkbox-color-check input:checked.bg-info ~ label::before,
.custom-radio.custom-radio-color input.bg-info ~ label::before,
.custom-radio.custom-radio-color-checked input:checked.bg-info ~ label::before,
.custom-switch.custom-switch-color input:checked.bg-info ~ label::before {
  background-color: #00d0ff !important;
  border-color: #00d0ff !important;
}

/* checkbox with icon */

.custom-checkbox.checkbox-icon label i {
  position: absolute;
  top: 6px;
  left: -21px;
  font-size: 10px;
  transform: scale(0);
  -webkit-transform: scale(0);
  -webkit-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
}

.custom-checkbox.checkbox-icon input:checked ~ label i {
  transform: scale(1);
  -webkit-transform: scale(1);
  color: #fff;
}

.custom-checkbox.checkbox-icon input:checked ~ label::after {
  display: none;
}

/* switch with icon */

.custom-switch.custom-switch-icon label .switch-icon-left,
.custom-switch.custom-switch-icon label .switch-icon-right,
.custom-switch.custom-switch-text label .switch-icon-left,
.custom-switch.custom-switch-text label .switch-icon-right {
  position: absolute;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  top: -3px;
}

.custom-switch.custom-switch-icon label .switch-icon-left i,
.custom-switch.custom-switch-icon label .switch-icon-right i {
  font-size: 10px;
}

.custom-switch.custom-switch-icon label .switch-icon-left,
.custom-switch.custom-switch-text label .switch-icon-left {
  left: 8px;
  right: auto;
  color: #fff;
}

.custom-switch.custom-switch-icon label .switch-icon-right,
.custom-switch.custom-switch-text label .switch-icon-right {
  right: 5px;
  left: auto;
}

.custom-switch.custom-switch-icon,
.custom-switch.custom-switch-text {
  padding-left: 0;
}

.custom-switch.custom-switch-icon .custom-control-label {
  height: 20px;
  width: 42px;
  padding: 0;
}

.custom-switch.custom-switch-text .custom-control-label {
  width: 46px;
}

.custom-switch.custom-switch-icon .custom-control-label::before,
.custom-switch.custom-switch-text .custom-control-label::before {
  height: 20px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  user-select: none;
  top: 0;
  left: 1px;
  width: 2.75rem;
}

.custom-switch.custom-switch-text .custom-control-label::before {
  width: 100%;
}

.custom-switch.custom-switch-icon .custom-control-label:after,
.custom-switch.custom-switch-text .custom-control-label:after {
  position: absolute;
  top: 1px;
  left: 2px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  user-select: none;
  height: 18px;
  width: 18px;
}

.custom-switch.custom-switch-icon .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(1.4rem);
  -ms-transform: translateX(1.4rem);
  transform: translateX(1.4rem);
}

.custom-switch.custom-switch-text .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(1.6rem);
  -ms-transform: translateX(1.6rem);
  transform: translateX(1.6rem);
}

.custom-switch-inner {
  display: block;
  text-align: center;
}

.custom-switch.custom-switch-text input:checked ~ label::before {
  color: #fff;
  content: attr(data-on-label);
  text-align: left;
}

.custom-switch.custom-switch-text label::before {
  color: #000;
  content: attr(data-off-label);
  width: 2.85rem;
  height: 20px;
  padding: 0 4px;
  font-size: 11px;
  line-height: 18px;
  text-align: right;
}

.custom-switch.custom-switch-text label:after {
  position: absolute;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  user-select: none;
  height: 16px;
  width: 16px;
}

.custom-switch.custom-switch-text input:checked ~ label.custom-control-label::after {
  transform: translateX(1.6rem);
  -webkit-transform: translateX(1.6rem);
}

.form-label-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  padding: 0.6rem;
  color: #475F7B;
  font-size: 0.85rem;
  opacity: 0;
}

.counter-value {
  color: #FFF;
  padding: 1px 6px;
  font-size: 0.6rem;
  border-radius: 0 0 5px 5px;
  margin-right: 1rem;
}

.vector-map.h-500 {
  width: 100%;
  height: 500px;
}

/*---------------------------------------------------------------------
slick Arrow
-----------------------------------------------------------------------*/

.slick-arrow.left {
  left: 0;
  top: 0;
}

.slick-arrow.right {
  right: 0;
  top: 0;
}

.slick-arrow {
  background: #f1f5fc;
  padding: 11px;
  font-size: 24px;
  position: absolute;
  line-height: 90px;
  z-index: 9;
}

.slick-arrow:hover {
  text-decoration: none;
}

/*---------------------------------------------------------------------
Calender
-----------------------------------------------------------------------*/

.fc-unthemed .fc-toolbar .fc-button {
  background: rgba(130, 122, 243, 0.2);
  background: -moz-linear-gradient(left, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(130, 122, 243, 0.2)), color-stop(100%, rgba(180, 122, 243, 0.2)));
  background: -webkit-linear-gradient(left, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  background: -o-linear-gradient(left, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  background: -ms-linear-gradient(left, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  background: linear-gradient(to right, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="var(--iq-primary)", endColorstr="#b47af3", GradientType=1);
  color: var(--iq-primary);
  border: none;
  text-transform: capitalize;
  border-radius: 15px;
  -webkit-border-radius: 15px;
}

.fc-unthemed .fc-toolbar .fc-button.fc-button-active,
.fc-unthemed .fc-toolbar .fc-button:active,
.fc-unthemed .fc-toolbar .fc-button:focus {
  color: #fff;
  background-color: var(--iq-primary);
}

.job-classification li,
.today-schedule li {
  list-style-type: none;
  margin-bottom: 10px;
}

.job-classification li > i,
.today-schedule li i {
  margin-right: 10px;
  border-radius: 50%;
  color: #fff;
}

.schedule-text span {
  display: block;
}

.calender-small .flatpickr-calendar.animate.inline {
  width: 100%;
  box-shadow: none;
}

.calender-small .dayContainer {
  width: 100%;
  min-width: auto;
}

.calender-small .flatpickr-days {
  width: 100%;
}

.calender-small .flatpickr-day.today {
  color: #fff;
  border-color: var(--iq-primary);
  background: var(--iq-primary);
}

.calender-small .flatpickr-day.today:hover,
.calender-small .flatpickr-day.today:focus {
  color: #fff;
  border-color: var(--iq-primary);
  background: var(--iq-primary);
}

.calender-small .flatpickr-months {
  margin-bottom: 10px;
}

.calender-small .flatpickr-current-month .flatpickr-monthDropdown-months {
  font-size: 16px;
}

.calender-small .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: transparent;
  color: var(--iq-primary);
}

.calender-small .flatpickr-current-month input.cur-year {
  font-size: 16px;
}

.calender-small .numInputWrapper:hover {
  background: transparent;
}

.calender-small .flatpickr-months .flatpickr-prev-month,
.calender-small .flatpickr-months .flatpickr-next-month {
  padding: 7px 10px;
}

.calender-small .flatpickr-day.selected,
.calender-small .flatpickr-day.startRange,
.calender-small .flatpickr-day.endRange,
.calender-small .flatpickr-day.selected.inRange,
.calender-small .flatpickr-day.startRange.inRange,
.calender-small .flatpickr-day.endRange.inRange,
.calender-small .flatpickr-day.selected:focus,
.calender-small .flatpickr-day.startRange:focus,
.calender-small .flatpickr-day.endRange:focus,
.calender-small .flatpickr-day.selected:hover,
.calender-small .flatpickr-day.startRange:hover,
.calender-small .flatpickr-day.endRange:hover,
.calender-small .flatpickr-day.selected.prevMonthDay,
.calender-small .flatpickr-day.startRange.prevMonthDay,
.calender-small .flatpickr-day.endRange.prevMonthDay,
.calender-small .flatpickr-day.selected.nextMonthDay,
.calender-small .flatpickr-day.startRange.nextMonthDay,
.calender-small .flatpickr-day.endRange.nextMonthDay {
  color: #fff;
  border-color: var(--iq-primary);
  background: var(--iq-primary);
}

.calender-small .flatpickr-day.inRange,
.calender-small .flatpickr-day.prevMonthDay.inRange,
.calender-small .flatpickr-day.nextMonthDay.inRange,
.calender-small .flatpickr-day.today.inRange,
.calender-small .flatpickr-day.prevMonthDay.today.inRange,
.calender-small .flatpickr-day.nextMonthDay.today.inRange,
.calender-small .flatpickr-day:hover,
.calender-small .flatpickr-day.prevMonthDay:hover,
.calender-small .flatpickr-day.nextMonthDay:hover,
.calender-small .flatpickr-day:focus,
.calender-small .flatpickr-day.prevMonthDay:focus,
.calender-small .flatpickr-day.nextMonthDay:focus {
  background: #edf3ff;
  color: var(--iq-primary);
  border-color: transparent;
}

.smaill-calender-home .flatpickr-calendar.animate.inline {
  margin: 0 auto;
  width: 100%;
  box-shadow: none;
  border: none;
  border-radius: 15px;
}

.smaill-calender-home .flatpickr-day.selected,
.smaill-calender-home .flatpickr-day.startRange,
.smaill-calender-home .flatpickr-day.endRange,
.smaill-calender-home .flatpickr-day.selected.inRange,
.smaill-calender-home .flatpickr-day.startRange.inRange,
.smaill-calender-home .flatpickr-day.endRange.inRange,
.smaill-calender-home .flatpickr-day.selected:focus,
.smaill-calender-home .flatpickr-day.startRange:focus,
.smaill-calender-home .flatpickr-day.endRange:focus,
.smaill-calender-home .flatpickr-day.selected:hover,
.smaill-calender-home .flatpickr-day.startRange:hover,
.smaill-calender-home .flatpickr-day.endRange:hover,
.smaill-calender-home .flatpickr-day.selected.prevMonthDay,
.smaill-calender-home .flatpickr-day.startRange.prevMonthDay,
.smaill-calender-home .flatpickr-day.endRange.prevMonthDay,
.smaill-calender-home .flatpickr-day.selected.nextMonthDay,
.smaill-calender-home .flatpickr-day.startRange.nextMonthDay,
.smaill-calender-home .flatpickr-day.endRange.nextMonthDay {
  color: #ffffff;
  text-decoration: none;
  background: var(--iq-primary);
  background: -moz-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, var(--iq-primary)), color-stop(100%, var(--iq-primary-light)));
  background: -webkit-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: -o-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: -ms-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="var(--iq-primary)", endColorstr="#b47af3", GradientType=1);
  text-decoration: none;
  border-color: var(--iq-primary);
}

.smaill-calender-home .flatpickr-days,
.smaill-calender-home .dayContainer {
  width: 100%;
}

.smaill-calender-home .flatpickr-innerContainer {
  justify-content: space-around;
}

/*---------------------------------------------------------------------
 Wizard
-----------------------------------------------------------------------*/

#form-wizard1,
#form-wizard2,
#form-wizard3 {
  position: relative;
}

#form-wizard1 input[type=file] {
  line-height: 27px;
}

#form-wizard1 fieldset:not(:first-of-type) {
  display: none;
}

.card {
  z-index: 0;
  border: none;
  position: relative;
}

.steps {
  font-size: 20px;
  color: #777D74;
  font-weight: normal;
  text-align: right;
}

#top-tab-list {
  margin: 0 -10px 60px;
  overflow: hidden;
  color: #777D74;
}

#top-tab-list .active {
  color: var(--iq-primary);
}

#top-tab-list li {
  list-style-type: none;
  font-size: 18px;
  width: 25%;
  float: left;
  text-align: left;
  position: relative;
  font-weight: 400;
}

#top-tab-list li i {
  display: inline-block;
  text-align: center;
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 20px;
  border-radius: 50%;
  margin: 0 15px 0 0;
  color: #ffffff;
  background: var(--iq-primary);
}

#top-tab-list li.active a {
  color: #fff;
  background: var(--iq-primary);
}

#top-tab-list li.active.done a {
  background: #27b345;
}

#top-tab-list li.active.done i {
  color: #27b345;
}

#top-tab-list li#confirm.active a {
  background: #27b345;
}

#top-tab-list li#confirm.active i {
  color: #27b345;
}

#top-tab-list li a {
  background: #edf3ff;
  color: var(--iq-primary);
  display: block;
  padding: 15px;
  margin: 0 10px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  text-decoration: none;
}

#top-tab-list li.active i {
  background-color: #fff;
  color: var(--iq-primary);
}

.fit-image {
  width: 100%;
  object-fit: cover;
}

/* validate */

.stepwizard-row {
  display: block;
  margin: 0 -20px;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}

.setup-content {
  margin-top: 60px;
}

.wizard-step {
  font-size: 18px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
  text-align: center;
}

.stepwizard-row .wizard-step a.btn {
  background: #fff;
  color: #374948;
  font-size: 18px;
  font-weight: 400;
  display: block;
  padding: 50px 15px;
  margin: 10px 20px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  text-decoration: none;
  border: 1px solid #f2edff;
  font-family: "Roboto", sans-serif;
}

.stepwizard-row .wizard-step i {
  display: block;
  text-align: center;
  height: 65px;
  width: 65px;
  line-height: 65px;
  font-size: 25px;
  border-radius: 50%;
  margin: 0 auto 20px;
  color: #ffffff;
  background: var(--iq-primary);
}

.stepwizard-row #user.active a {
  background: #edf3ff;
  border-color: var(--iq-primary);
}

.stepwizard-row #document.active a {
  background: #fff5f4;
  border-color: #f14336;
}

.stepwizard-row #bank.active a {
  background: #eefff2;
  border-color: #27b345;
}

.stepwizard-row #confirm.active a {
  background: #fffcf3;
  border-color: #fbc647;
}

.stepwizard-row .wizard-step#user i {
  background: #edf3ff;
  color: var(--iq-primary);
}

.stepwizard-row .wizard-step#document i {
  background: #fff5f4;
  color: #f14336;
}

.stepwizard-row .wizard-step#bank i {
  background: #eefff2;
  color: #27b345;
}

.stepwizard-row .wizard-step#confirm i {
  background: #fffcf3;
  color: #fbc647;
}

.stepwizard-row .wizard-step.active i {
  background-color: #fff !important;
}

.stepwizard-row .wizard-step#document.active i {
  background: #fff5f4;
  color: #f14336;
}

.stepwizard-row .wizard-step#bank.active i {
  background: #eefff2;
  color: #27b345;
}

.stepwizard-row .wizard-step#confirm.active i {
  background: #fffcf3;
  color: #fbc647;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #a94442;
}

.has-error .form-control {
  border: 1px solid #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  pointer-events: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* vertical */

#form-wizard3 fieldset:not(:first-of-type) {
  display: none;
}

#top-tabbar-vertical {
  overflow: hidden;
  color: #777D74;
  margin: 0 -20px 60px;
}

#top-tabbar-vertical .active {
  color: var(--iq-primary);
}

#top-tabbar-vertical li {
  list-style-type: none;
  font-size: 18px;
  width: 100%;
  float: left;
  position: relative;
  font-weight: 400;
}

#top-tabbar-vertical li i {
  display: inline-block;
  text-align: center;
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 20px;
  border-radius: 50%;
  margin: 0 20px 0 0;
}

#top-tabbar-vertical li#personal i {
  background: #edf3ff;
  color: var(--iq-primary);
}

#top-tabbar-vertical li#contact i {
  background: #fff5f4;
  color: #f14336;
}

#top-tabbar-vertical li#official i {
  background: #eefff2;
  color: #27b345;
}

#top-tabbar-vertical li#payment i {
  background: #fffcf3;
  color: #fbc647;
}

#top-tabbar-vertical li.active a {
  border-color: transparent;
}

#top-tabbar-vertical li a {
  background: #fff;
  color: #000;
  display: block;
  padding: 20px 15px;
  margin: 10px 20px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  text-decoration: none;
  border: 1px solid #f2edff;
}

#top-tabbar-vertical li.active i {
  background-color: #fff;
  color: var(--iq-primary);
}

#top-tabbar-vertical #personal.active a {
  background: #edf3ff;
}

#top-tabbar-vertical #contact.active a {
  background: #fff5f4;
}

#top-tabbar-vertical #official.active a {
  background: #eefff2;
}

#top-tabbar-vertical #payment.active a {
  background: #fffcf3;
}

#top-tabbar-vertical li.active i {
  background: #fff !important;
}

/*---------------------------------------------------------------------
Chat
-----------------------------------------------------------------------*/

.chat-page img {
  border-radius: 30px;
}

#chat-search {
  padding-left: 40px;
}

.form-group.chat-search-data > i {
  position: absolute;
  top: 8px;
  left: 15px;
  color: var(--iq-primary);
  font-size: 16px;
}

.chat-sidebar-name {
  width: 100%;
}

.chat-msg-counter {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  line-height: 22px;
  margin: 0 auto;
  font-size: 10px;
}

.chat-meta span {
  font-size: 10px;
}

span.avatar-status {
  position: absolute;
  bottom: -5px;
  top: auto;
  left: auto;
  right: 0;
}

.iq-chat-ui li a {
  text-decoration: none;
  display: block;
  padding: 20px 15px;
  border-bottom: 1px solid #f1f2f1;
}

.iq-chat-ui li a.active {
  border-color: transparent;
}

.nav-pills li a.active {
  color: var(--iq-primary);
  background-color: #edf3ff;
}

.iq-chat-ui li:last-child a {
  border: none;
}

.iq-chat-ui li:nth-child(2) a,
.iq-chat-ui li:nth-child(6) a {
  border: none;
}

.chat-sidebar-channel {
  overflow-y: scroll;
  overflow-x: hidden;
  /*height: 450px;*/
  height: calc(100vh - 19rem);
  margin-bottom: 15px;
}

.chat-header-icons .dropdown-toggle:empty::after {
  display: none;
}

.chat-header-icons > a,
.chat-header-icons > span {
  width: 40px;
  height: 40px;
  font-size: 20px;
  margin: 0 5px 0 0;
  color: var(--iq-primary);
  display: inline-block;
  text-align: center;
  line-height: 40px;
  border-radius: 10px;
  cursor: pointer;
}

.chat-content {
  position: relative;
  height: calc(100vh - 17rem);
  text-align: center;
  padding: 1.4rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-user {
  float: right;
  text-align: center;
}

.chat-user span.chat-time {
  display: block;
  font-size: 12px;
  font-weight: 500;
}

.chat-detail {
  overflow: hidden;
}

.chat-message {
  position: relative;
  float: right;
  text-align: right;
  padding: 0.75rem 1rem;
  margin: 0 1.33rem 1.33rem 0.3rem;
  clear: both;
  word-break: break-word;
  color: #FFF;
  background: var(--iq-primary);
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(90, 141, 238, 0.6);
}

.chat-message > p {
  margin: 0;
}

.chat-left .chat-message::after {
  right: auto;
  left: -7px;
  border-width: 5px 7px 5px 0;
  border-color: transparent #fff transparent transparent;
}

.chat-content .chat-left .chat-message {
  text-align: left;
  float: left;
  margin: 0 0.3rem 1.8rem 1.3rem;
  color: #727E8C;
  background-color: #fff;
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
}

.chat-sidebar-channel .avatar,
.avatar {
  position: relative;
}

.chat-start {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 10rem);
  flex-direction: column;
}

span.iq-start-icon {
  font-size: 50px;
  background: #fff;
  width: 100px;
  text-align: center;
  border-radius: 50%;
}

.chat-attagement {
  font-size: 20px;
}

.chat-attagement a {
  color: #777D74;
}

.chat-detail .chat-message .chat-time {
  position: absolute;
  bottom: -20px;
  right: 0;
  color: #828D99;
  font-size: 0.8rem;
  white-space: nowrap;
}

.chat-left .chat-user {
  float: left;
}

.chat-content .chat-body .chat-message p {
  margin-bottom: 0;
}

.chat-data .tab-content {
  position: relative;
}

.chat-searchbar {
  position: relative;
}

.chat-user-detail-popup {
  position: absolute;
  top: 0;
  width: 360px;
  background: #fff;
  right: 0;
  overflow: hidden;
  left: auto;
  z-index: 9;
  height: 100%;
  opacity: 0;
  transform: translateX(110%);
  -webkit-transform: translateX(110%);
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
  border-radius: 5px;
  visibility: hidden;
}

.chat-user-detail-popup.show {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
}

.chatuser-detail .title {
  font-weight: 500;
}

.chatuser-detail {
  padding: 0 35px;
}

.user-profile button.close-popup {
  display: block;
  font-size: 24px;
}

ul.user-status {
  list-style: none;
}

#user-detail-popup {
  position: absolute;
  top: 0;
  width: 310px;
  background: #fff;
  right: 0;
  overflow: hidden;
  left: auto;
  z-index: 9;
  height: 100%;
  opacity: 0;
  transform: translateX(-90%);
  -webkit-transform: translateX(-90%);
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
  border-radius: 5px;
  visibility: hidden;
}

#user-detail-popup.show {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
}

#user-detail-popup .user-profile button.close-popup {
  text-align: right;
  width: 100%;
}

.chat-sidebar-channel h5 {
  border-bottom: 1px solid #f1f2f1;
  padding-bottom: 10px;
}

.chat-sidebar-name h6 {
  font-size: 1.2em;
}

.chat-sidebar-name span {
  color: #777D74;
}

button.close-btn-res {
  display: none;
}

div#sidebar-toggle {
  display: none;
}

/*---------------------------------------------------------------------
Profile Page
-----------------------------------------------------------------------*/

.profile-img img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.cover-container {
  position: relative;
}

ul.header-nav {
  position: absolute;
  left: auto;
  right: 0;
  top: auto;
  bottom: 20px;
}

ul.header-nav li {
  list-style: none;
}

ul.header-nav li a {
  display: block;
  list-style: none;
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  color: var(--iq-primary);
  font-size: 18px;
  margin: 0 15px 0 0;
}

.profile-img {
  margin-top: -80px;
}

.profile-feed-items li {
  list-style: none;
}

.profile-feed-items li:last-child a {
  margin: 0;
}

.profile-feed-items li a {
  text-decoration: none;
  text-transform: capitalize;
  color: var(--iq-primary);
  background-color: #e0defc;
  margin: 0 20px 0 0;
}

.nav-pills.profile-feed-items li a.active {
  color: #fff;
  background: var(--iq-primary);
}

.news-icon {
  font-size: 18px;
  margin-right: 20px;
}

ul.profile-img-gallary {
  list-style: none;
  margin: 0 -1px 0 -4px !important;
}

.twit-feed p span i {
  background: var(--iq-primary);
  color: #fff;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  font-size: 10px;
}

.twit-feed .twit-meta-tag {
  color: var(--iq-primary);
}

.twit-feed .media-support-user-img img {
  height: 50px;
}

ul.media-story img {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2px;
}

ul.media-story li.active img {
  border-color: var(--iq-primary);
}

.add-suggestion {
  border: 1px solid #007bff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  font-size: 16px;
  color: #007bff;
}

.suggestions-lists .btn {
  display: block;
}

.feather-icon a,
.message-icon a {
  color: #777D74;
  font-size: 18px;
  font-weight: 300;
}

ul.post-comments {
  list-style: none;
}

.comment-activity > a {
  margin-right: 10px;
  text-transform: capitalize;
  color: var(--iq-primary);
  text-decoration: none;
}

form.comment-text {
  position: relative;
}

.comment-attagement {
  position: absolute;
  left: auto;
  right: 0;
  font-size: 20px;
}

.comment-attagement a {
  color: #777D74;
}

#profile-activity .iq-timeline li {
  margin-bottom: 15px;
}

.about-info a,
div#profile-profile a {
  color: #777D74;
}

.twit-date a {
  color: #777D74;
}

/*---------------------------------------------------------------------
Profile Edit
-----------------------------------------------------------------------*/

.profile-pic {
  max-width: 100%;
  display: block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}

.profile-img-edit {
  position: relative;
  display: inline-block;
  height: 150px;
  width: 150px;
}

.file-upload {
  display: none;
}

.p-image {
  position: absolute;
  top: auto;
  right: 8px;
  bottom: 10px;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: var(--iq-primary);
  color: #fff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}

.iq-edit-profile .nav-link {
  padding: 20px 15px;
  border-radius: 0;
  color: #777D74;
  text-align: center;
  font-size: 16px;
  border-left: 1px solid #f2edff;
}

.iq-edit-profile.nav-pills .nav-link.active,
.iq-edit-profile.nav-pills .show > .nav-link {
  color: #fff;
  background: var(--iq-primary);
  background: -moz-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, var(--iq-primary)), color-stop(100%, var(--iq-primary-light)));
  background: -webkit-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: -o-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: -ms-linear-gradient(left, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="var(--iq-primary)", endColorstr="#b47af3", GradientType=1);
}

ul.iq-edit-profile li:first-child a {
  border-radius: 15px 0 0 15px;
  border-left: none;
}

ul.iq-edit-profile li:last-child a {
  border-radius: 0 15px 15px 0;
}

/*---------------------------------------------------------------------
Todo Page
-----------------------------------------------------------------------*/

.iq-todo-page .search-link,
.iq-todo-right .search-link {
  position: absolute;
  top: 0;
  left: 10px;
  font-size: 18px;
  line-height: 43px;
  color: #777D74;
}

.iq-todo-page .todo-search,
.iq-todo-right .todo-search {
  padding-left: 35px;
}

.add-new-project > a {
  padding: 0 15px;
  color: #777D74;
  text-transform: capitalize;
  text-decoration: none;
}

.notification-icon > span {
  height: 11px;
  width: 11px;
  border-radius: 50%;
  line-height: 13px;
  font-size: 8px;
  text-align: center;
  position: absolute;
  top: -5px;
  left: auto;
  right: -8px;
}

.todo-date {
  font-size: 16px;
}

.iq-todo-page ul {
  list-style: none;
}

.iq-todo-page ul li a {
  color: #777D74;
  text-decoration: none;
}

.iq-todo-page ul ul li a {
  padding-left: 25px;
}

.iq-todo-page ul > li > a {
  padding: 10px 15px;
  display: block;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.iq-todo-page ul > li[aria-expanded=true] > a {
  color: var(--iq-primary);
  background: #edf3ff;
}

.iq-todo-page ul > li li:hover a,
.iq-todo-page ul > li li.active a {
  color: var(--iq-primary);
  background: #edf3ff;
}

ul.todo-task-lists li:hover {
  background: #edf3ff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

ul.todo-task-lists li.active-task {
  background: #edf3ff;
}

.media-support-info p {
  line-height: normal;
}

.todo-task-list .sub-task {
  display: none;
}

.todo-task-list .sub-task.show {
  display: block;
}

/*---------------------------------------------------------------------
Privacy Policy Page
-----------------------------------------------------------------------*/

.input-group .input-group-text {
  background: #e9edf4;
  border: 0px solid #d7dbda;
}

.data-privacy {
  margin: 30px 0;
}

/*---------------------------------------------------------------------
User List Page
-----------------------------------------------------------------------*/

.list-user-action a {
  font-size: 16px;
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 25px;
  margin: 0 3px;
  display: inline-block;
  border-radius: 5px;
}

#user-list-table.table td {
  vertical-align: middle;
}

.user-list-files a {
  padding: 10px 15px;
  margin: 0 0 0 10px;
  border-radius: 15px;
  line-height: normal;
}

.table-responsive #exampleInputSearch {
  width: auto;
}

/*---------------------------------------------------------------------
Add User Page
-----------------------------------------------------------------------*/

.add-img-user .p-image {
  position: static;
  text-align: left;
  display: block;
  height: auto;
  width: auto;
  background: transparent;
  margin-top: 20px;
}

.img-extension a,
.img-extension span {
  color: #777D74;
  padding: 0 3px;
  font-size: 13px;
}

/*---------------------------------------------------------------------
Horizontal Menu Page
-----------------------------------------------------------------------*/

.iq-page-menu-horizontal .content-page {
  margin-left: 0;
}

.iq-page-menu-horizontal .iq-footer {
  margin-left: 0;
}

.iq-page-menu-horizontal .iq-sidebar-logo {
  padding-top: 0;
  display: block;
}

.iq-page-menu-horizontal .iq-top-navbar .iq-sidebar-logo .top-logo {
  display: block;
}

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li ul {
  position: absolute;
  top: 100%;
  padding: 0 15px;
  min-width: 200px;
  background: #fff;
  z-index: 9;
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
}

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li ul #sidebar-scrollbar {
  height: 50vh;
}

.iq-menu-horizontal .iq-sidebar-menu li.menu-open > a,
.iq-menu-horizontal .iq-sidebar-menu li:hover > a {
  color: var(--iq-primary);
  background: transparent;
}

.iq-menu-horizontal .iq-sidebar-menu li:hover > .iq-submenu {
  display: block;
}

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li.menu-open .iq-arrow-right,
.iq-menu-horizontal .iq-sidebar-menu .iq-menu li:hover .iq-arrow-right {
  transform: rotate(90deg);
}

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li a {
  padding: 15px 50px 15px 0;
}

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li ul li a {
  padding: 12px 10px;
}

body.iq-page-menu-horizontal .iq-top-navbar {
  width: 97%;
}

/*---------------------------------------------------------------------
index Page
-----------------------------------------------------------------------*/

.iq-welcome-block-data {
  padding: 16px 0;
}

.iq-welcome-block-data .img-element {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.iq-welcome-block-data img.img-element.bottom {
  top: auto;
}

.iq-welcome-block-data .img-element.right-img {
  left: auto;
}

.iq-welcome-block-data .img-element.top-left {
  left: auto;
  bottom: auto;
  top: -50px;
}

.iq-welcome-block-data .img-element.left-img {
  width: 100%;
}

/*---------------------------------------------------------------------
Index 1
-----------------------------------------------------------------------*/

.profile-icon {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  border-radius: 50%;
}

.icon.icon-box {
  font-size: 40px;
  line-height: 50px;
}

.user-img span.avatar-status {
  bottom: -10px;
  right: -5px;
}

.left-icon-box a {
  text-align: center;
  display: block;
  font-size: 50px;
  width: 120px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 10px;
}

/* progress bar */

.progress-round {
  width: 50px;
  height: 50px;
  background: none;
  position: relative;
}

.progress-round::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
}

.progress-round > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress-round .progress-left {
  left: 0;
}

.progress-round .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 3px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress-round .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress-round .progress-right {
  right: 0;
}

.progress-round .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.progress-round .progress-value {
  position: absolute;
  top: 0;
  left: 0;
}

#home-chart-03 .apexcharts-legend.position-right {
  right: 35px !important;
}

/*---------------------------------------------------------------------
Index - 2
-----------------------------------------------------------------------*/

.iq-iconbox {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 25px;
  border-radius: 10px;
}

.iq-scale-border {
  height: 50px;
  width: 50px;
  bordiq-er: 8px solid;
  border-radius: 50%;
}

.iq-scale-border.like-block {
  border-color: #e0defc;
}

.iq-scale-border.comment-block {
  border-color: #e5fafc;
}

.iq-scale-border.share-block {
  border-color: #fedc8a;
}

.iq-scale-border.repost-block {
  border-color: #fa847b;
}

.iq-scale-border.view-block {
  border-color: #56d172;
}

.iq-scale-border.post-block {
  border-color: #cacaca;
}

#post-slider .slick-arrow {
  top: -64px;
  background: transparent;
  height: 30px;
  width: 30px;
  color: #777D74;
  border: 1px solid #777D74;
  padding: 0;
  line-height: 30px;
  text-align: center;
}

#post-slider .slick-arrow.left {
  left: auto;
  right: 35px;
}

.iq-caption {
  position: absolute;
  top: 30px;
  left: 50px;
  color: #fff;
}

.iq-caption h1 {
  color: #fff;
  font-size: 60px;
  line-height: 60px;
  font-weight: 800;
}

.iq-caption p {
  color: #fff;
  font-size: 18px;
  letter-spacing: 2px;
  text-transform: lowercase;
}

/* Custom */

.pro-bg-card {
  background-color: #fff;
}

.pro-task-bg-card {
  background-color: #E9EDF4;
}

ul#topbar-data-icon {
  margin: -60px 10px 0;
}

ul#topbar-data-icon {
  margin: -30px 10px 0;
}

.topbar-menu-icon li a {
  background: #ffffff;
  padding: 5px 10px;
  margin: 0 0 0 15px;
  border-radius: 10px;
}

.searchbox-datalink {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  border-radius: 15px;
  box-shadow: 0px 0px 12px 0px rgba(120, 146, 141, 0.06);
}

.searchbox-datalink.show-data {
  opacity: 1;
  visibility: visible;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  transform: translateY(100%);
}

.ag-theme-balham .ag-paging-panel {
  font-weight: bold;
}

.iq-global-search > ul > li a,
.slick-arrow,
.breadcrumb-item > a {
  color: var(--iq-primary);
}

.iq-global-search > ul > li a:hover,
.slick-arrow:hover,
.breadcrumb-item > a:hover {
  color: var(--iq-primary);
}

.user-actions a,
.user-actions button {
  padding: 0;
  vertical-align: middle;
}

.iq-card-header-toolbar a,
.iq-card-header-toolbar button {
  margin-left: 10px;
}

.flag {
  margin: -7px 0 0 -16px;
}

.flag-w-text span {
  vertical-align: middle;
}

.flag-w-text .flag {
  margin: 0 -22px;
}

.form-control:disabled,
.form-control[readonly] {
  background: #e9ecef;
}

.form-control:disabled.flatpickr-input,
.form-control[readonly].flatpickr-input {
  background: none;
}

[dir=ltr] textarea.form-control {
  min-height: 100px;
  line-height: 1.8;
}

select.form-control {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

select.form-control[multiple] {
  min-height: 100px !important;
}

.batch-form select.custom-select {
  border-radius: 10px;
}

.batch-form .btn {
  padding: 4.3px 10px;
}

.d-flex .badge {
  line-height: 1.8;
}

select.form-control[multiple],
select.form-control[size] {
  min-height: 36px;
}

.custom-file-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 80px;
  overflow: hidden;
}

.btn-outline-primary:hover {
  color: #ffffff !important;
}

.custom-radio {
  padding-top: 5px;
  padding-bottom: 5px;
}

.custom-switch {
  padding-top: 5px;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.modal-body .pdf-preview {
  padding: 30px;
  font-size: 10px;
  line-height: 16px;
  font-family: Verdana, sans-serif;
  color: #000000;
  width: 794px;
}

.modal-body .pdf-preview table {
  width: 100%;
  margin: 0;
}

.modal-body .pdf-preview table thead {
  background: #cccccc;
}

.modal-body .pdf-preview table thead th {
  border-width: 1px;
}

.modal-body .pdf-preview table tbody td {
  vertical-align: top;
}

.modal-body .pdf-preview table strong {
  font-size: 12px;
}

.modal-body .pdf-preview table p {
  margin: 0;
}

.modal-body .pdf-preview table:not(.text-center) th:last-child,
.modal-body .pdf-preview table:not(.text-center) td:last-child {
  text-align: right;
}

.modal-body .pdf-preview ul {
  margin: 0;
  padding: 0;
}

.modal-body .pdf-preview p,
.modal-body .pdf-preview li,
.modal-body .pdf-preview td {
  font-size: 10px;
  line-height: 16px;
  margin: 0;
}

.modal-body .pdf-preview p {
  margin: 0 0 1rem;
}

.modal-body .pdf-preview a {
  color: inherit;
}

#pdfPreview .modal-body {
  padding: 0;
}

.trumbowyg-editor table td {
  padding: 0 !important;
  border: 0 !important;
}

.multiselect .multiselect__select {
  height: 43px;
}

.multiselect .multiselect__tags {
  min-height: 45px;
  border-radius: 10px;
}

.multiselect .multiselect__tags .multiselect__input,
.multiselect .multiselect__tags .multiselect__single {
  font-size: 14px;
  padding: 0;
  line-height: 28px;
  margin: 0;
}

.multiselect .multiselect__content {
  max-width: 100%;
}

.multiselect .multiselect__content .multiselect__option {
  padding: 8px 6px;
  min-height: 24px;
  line-height: 12px;
  font-size: 12px;
}

.multiselect.is-invalid .multiselect__tags {
  border-color: red;
}

.height-400 {
  height: calc(100vh - 300px) !important;
}

.ag-theme-balham .ag-cell {
  white-space: normal !important;
  line-height: 18px !important;
}

.dropzone .dz-message {
  margin: 9rem 0 !important;
  font-size: 1rem;
}

.iq-email-listbox .iq-email-sender-list > li.iq-unread {
  background: #f0f0f0;
}

.bg-danger-light {
  background: rgba(253, 98, 86, 0.5) !important;
}

.bg-danger-dark {
  background: #fd6256 !important;
}

.bg-info-light {
  background-color: #e6e6e6 !important;
}

.iq-profile-avatar.status-primary:before {
  background-color: #6a75ca;
}

.iq-profile-avatar.status-blue:before {
  background-color: #468bd8;
}

.iq-profile-avatar.status-info:before {
  background-color: #17a2b8;
}

.iq-profile-avatar.status-danger:before {
  background-color: #ea5455;
}

.bg-blue {
  background-color: #468bd8 !important;
}

.dash-hover-gradient {
  background-color: #fff;
  min-height: 200px;
}

.dash-hover-gradient:hover {
  cursor: pointer;
  background: var(--iq-primary);
  color: #fff;
}

.dash-hover-scale {
  min-height: 200px;
  transition: all 200ms ease-in-out;
}

.dash-hover-scale:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: all 200ms ease-in-out;
}

.dash-hover-gradient:hover .iq-card-body *,
.dash-hover-gradient:hover .iq-card-header i {
  color: #fff;
}

.transformY-min-15 {
  transform: translateY(-15px);
}

.transformY-min-45 {
  transform: translateY(-45px);
}

.text-gray {
  color: #aaa;
}

.top-10 {
  top: 10% !important;
}

.top-20 {
  top: 20% !important;
}

.top-30 {
  top: 30% !important;
}

.top-40 {
  top: 40% !important;
}

.top-50 {
  top: 50% !important;
}

.top-60 {
  top: 60% !important;
}

.top-70 {
  top: 70% !important;
}

.top-80 {
  top: 80% !important;
}

.top-90 {
  top: 90% !important;
}

.top-100 {
  top: 100% !important;
}

.top-150 {
  top: 150% !important;
}

.top-200 {
  top: 200% !important;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-34 {
  font-size: 34px;
}

.font-size-36 {
  font-size: 36px;
}

.font-size-38 {
  font-size: 38px;
}

.font-size-40 {
  font-size: 40px;
}

.height-25 {
  height: 25px !important;
}

.height-50 {
  height: 50px !important;
}

.height-75 {
  height: 75px !important;
}

.height-100 {
  height: 100px !important;
}

.height-125 {
  height: 125px !important;
}

.height-150 {
  height: 150px !important;
}

.height-300 {
  height: 300px !important;
}

.height-500 {
  height: 500px !important;
}

.chartjs-pie-chart {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.chart-legend {
  margin: 0;
  padding: 0;
}

.chart-legend {
  display: inline-block;
  vertical-align: middle;
  width: 80%;
  font-size: 14px;
}

.chart-legend ul {
  padding: 0px;
}

.chart-legend li {
  line-height: 1.3em;
  list-style: none;
  margin: 0 0 0.3em 0;
  min-height: 1.3em;
}

.chart-legend li span {
  border-radius: 0.3em;
  display: inline-block;
  height: 1.3em;
  left: 0;
  margin-right: 0.5em;
  vertical-align: middle;
  width: 1.3em;
}

.apexcharts-gridlines-horizontal {
  display: none !important;
}

/*  Css for the Dashboard 2 */

.iq-border-radius-5 {
  border-radius: 5px;
}

.iq-border-radius-10 {
  border-radius: 10px;
}

.iq-border-radius-15 {
  border-radius: 15px;
}

.iq-border-radius-20 {
  border-radius: 20px;
}

.iq-border-r-5 {
  border-right: 5px solid;
}

.iq-border-l-5 {
  border-left: 5px solid;
}

.iq-shadow {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}

.iq-profile-card {
  overflow: visible;
}

.right-section {
  min-height: 80vh;
  border-radius: 50px;
}

.iq-icon-shape {
  padding: 12px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
}

/* Project 7 Dashboard */

.kanban-drag,
.kanban-item {
  padding: 5px !important;
}

.kanban-drag {
  height: 600px;
  overflow: hidden;
  overflow-y: scroll;
}

.gantt-container {
  height: 350px;
}

.bg-banner-image {
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  height: 250px;
}

.banner-bg-color-primary {
  width: 100%;
  height: 100%;
  background: rgba(106, 117, 202, 0.8);
  border-radius: inherit;
}

.iq-mt--50 {
  margin-top: -50px;
}

.full-map {
  height: 270px;
  z-index: 0;
}

.track {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  overflow-x: scroll;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 20px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 20px;
}

.dash-tracking-icon {
  height: 80px;
}

.transformX-min-1 {
  transform: scaleX(-1);
}

.iq-page-primary > li > a,
.iq-page-primary > li > span {
  background-color: var(--iq-primary);
  color: #fff;
}

.iq-page-primary > .page-item.active .page-link {
  border-color: var(--iq-primary-dark);
  background-color: var(--iq-primary-dark);
}

.iq-page-primary > .page-item.disabled .page-link {
  background-color: var(--iq-primary);
  color: #fff;
}

.iq-page-success > li > a,
.iq-page-success > li > span {
  background-color: #00ca00;
  color: #fff;
}

.iq-page-success > .page-item.active .page-link {
  border-color: #009500;
  background-color: #009500;
}

.iq-page-success > .page-item.disabled .page-link {
  background-color: #00ca00;
  color: #fff;
}

.iq-page-info > li > a,
.iq-page-info > li > span {
  background-color: #00d0ff;
  color: #fff;
}

.iq-page-info > .page-item.active .page-link {
  border-color: #008fb5;
  background-color: #008fb5;
}

.iq-page-info > .page-item.disabled .page-link {
  background-color: #00d0ff;
  color: #fff;
}

.iq-email-list .iq-email-ui li a {
  padding: 0.5rem 1rem !important;
}

.custom-checkbox.custom-checkbox-color input[color=primary] ~ label::before,
.custom-checkbox.custom-checkbox-color-check input:checked[color=primary] ~ label::before,
.custom-radio.custom-radio-color input[color=primary] ~ label::before,
.custom-radio.custom-radio-color-checked input:checked[color=primary] ~ label::before,
.custom-switch.custom-switch-color input:checked[color=primary] ~ label::before {
  background-color: var(--iq-primary) !important;
  border-color: var(--iq-primary) !important;
}

.custom-checkbox.custom-checkbox-color input[color=success] ~ label::before,
.custom-checkbox.custom-checkbox-color-check input:checked[color=success] ~ label::before,
.custom-radio.custom-radio-color input[color=success] ~ label::before,
.custom-radio.custom-radio-color-checked input:checked[color=success] ~ label::before,
.custom-switch.custom-switch-color input:checked[color=success] ~ label::before {
  background-color: #00ca00 !important;
  border-color: #00ca00 !important;
}

.custom-checkbox.custom-checkbox-color input[color=danger] ~ label::before,
.custom-checkbox.custom-checkbox-color-check input:checked[color=danger] ~ label::before,
.custom-radio.custom-radio-color input[color=danger] ~ label::before,
.custom-radio.custom-radio-color-checked input:checked[color=danger] ~ label::before,
.custom-switch.custom-switch-color input:checked[color=danger] ~ label::before {
  background-color: #e64141 !important;
  border-color: #e64141 !important;
}

.custom-checkbox.custom-checkbox-color input[color=warning] ~ label::before,
.custom-checkbox.custom-checkbox-color-check input:checked[color=warning] ~ label::before,
.custom-radio.custom-radio-color input[color=warning] ~ label::before,
.custom-radio.custom-radio-color-checked input:checked[color=warning] ~ label::before,
.custom-switch.custom-switch-color input:checked[color=warning] ~ label::before {
  background-color: #ffd400 !important;
  border-color: #ffd400 !important;
}

.custom-checkbox.custom-checkbox-color input[color=dark] ~ label::before,
.custom-checkbox.custom-checkbox-color-check input:checked[color=dark] ~ label::before,
.custom-radio.custom-radio-color input[color=dark] ~ label::before,
.custom-radio.custom-radio-color-checked input:checked[color=dark] ~ label::before,
.custom-switch.custom-switch-color input:checked[color=dark] ~ label::before {
  background-color: #374948 !important;
  border-color: #374948 !important;
}

.custom-checkbox.custom-checkbox-color input[color=info] ~ label::before,
.custom-checkbox.custom-checkbox-color-check input:checked[color=info] ~ label::before,
.custom-radio.custom-radio-color input[color=info] ~ label::before,
.custom-radio.custom-radio-color-checked input:checked[color=info] ~ label::before,
.custom-switch.custom-switch-color input:checked[color=info] ~ label::before {
  background-color: #00d0ff !important;
  border-color: #00d0ff !important;
}

/*.router-anim-enter-active{
    animation: out 2s;
    animation-delay: 1s;
}

.router-anim-leave-active{
    animation: coming 1s;
}

@keyframes coming {
    from {
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(100%);
    }
}
@keyframes out {
    0% {
        opacity: 0;
        transform: translateX(0%);
    }
    25% {
        opacity: 0;
        transform: translateX(100%);
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    100%{
        opacity: 1;
        transform: translateX(0%);
    }
}*/

.dropdown-menu:focus {
  outline: none !important;
}

.modal-backdrop {
  opacity: 0.5 !important;
}

.fc-button-primary:not(:disabled):active:focus,
.fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none !important;
}

.setting-toggle {
  padding-right: 12px;
}

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li.active ul {
  display: none !important;
}

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li > a::before {
  background: unset;
}

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li.active > a {
  color: unset;
  background: unset;
}

.tui-full-calendar-weekday-schedule-title,
.tui-full-calendar-time-schedule {
  font-weight: normal !important;
}

.text-black {
  color: #424242;
}

.bg-gray {
  background-color: #cecece;
}

.iq-sidebar.sidebar-dark .iq-menu li.active .iq-submenu li > .router-link-exact-active {
  background: #1e1e1e !important;
  color: #fff !important;
}

.iq-sidebar-menu .iq-menu li.active .iq-submenu li.active > .router-link-exact-active {
  background: #e0defc !important;
  color: var(--iq-primary) !important;
  border-radius: 0;
}

/*---------------------------------------------------------------------
Ecommerce Pages Page
-----------------------------------------------------------------------*/

ul {
  list-style: none;
}

ul.grid_list li a {
  background: #e5f2ff;
  color: var(--iq-primary);
  font-size: 18px;
  text-align: center;
  line-height: 38px;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 5px;
}

.Products ul.product_list {
  list-style: none;
  margin: 0 -10px;
  padding: 0;
}

.product-miniature {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}

.thumbnail-container {
  position: relative;
  overflow: hidden;
}

.product-action {
  position: relative;
  float: left;
  transition: all 0.45s ease 0s;
}

.Products li:hover .product-miniature .product-action {
  transition: all 0.45s ease 0s;
}

.product-action > div {
  display: inline-block;
}

.product-action a {
  background: #e5f2ff;
  color: var(--iq-primary);
  font-size: 18px;
  text-align: center;
  line-height: 38px;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.product-description {
  padding: 20px;
  background: #f3f7fd;
  border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
}

.regular-price {
  color: #000;
  font-size: 20px;
  margin-bottom: 10px;
}

.regular-price span {
  color: #777D74;
  text-decoration: line-through;
}

.shipping span,
.stock span {
  color: #000;
}

input.increment {
  text-align: center;
}

.ratting {
  margin: 0 0 10px;
  font-size: 18px;
  color: var(--iq-primary);
}

.thumbnail-container img {
  border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
}

.thumbnail-container .new {
  position: absolute;
  top: 15px;
  left: auto;
  right: 15px;
  background: #fff;
  font-size: 12px;
  padding: 2px 7px;
  border-radius: 4px;
}

/*---------------------------------------------------------------------
Product Page
-----------------------------------------------------------------------*/

.additional-product-action .product-action .add-to-cart a {
  width: auto;
  padding: 0 20px;
  background: var(--iq-primary);
  color: #fff;
}

.description-tabs {
  margin-top: 100px;
}

.iq-product-cover img,
.iq-additional_slider img {
  border-radius: 5px;
}

.description-tabs .nav-item .nav-link {
  font-size: 18px;
}

#related-slider .slick-arrow {
  top: 45%;
  height: 40px;
  width: 40px;
  padding: 0;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
}

.btn-increment button,
.btn-increment input {
  background: transparent;
  border: 1px solid #f2edff;
}

/*---------------------------------------------------------------------
Checkout Page
-----------------------------------------------------------------------*/

.fieldlabels {
  color: #455A64 !important;
  text-align: left;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: #455A64;
  padding-left: 0px;
  margin-top: 30px;
}

#progressbar li {
  list-style-type: none;
  font-size: 13px;
  width: 33.33%;
  float: left;
  position: relative;
  font-weight: 400;
}

#progressbar #step1:before {
  content: "1";
  color: #fff;
}

#progressbar #step2:before {
  content: "2";
  color: #fff;
}

#progressbar #step3:before {
  content: "3";
  color: #fff;
}

#progressbar li:before {
  width: 40px;
  height: 40px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  background: #455A64;
  border-radius: 50%;
  margin: auto;
  padding: 0px;
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #455A64;
  position: absolute;
  left: 0;
  top: 21px;
  z-index: -1;
}

#progressbar li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  left: -50%;
}

#progressbar li:nth-child(2):after {
  left: -50%;
}

#progressbar li:first-child:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 50%;
}

#progressbar li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#progressbar li:first-child:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: var(--iq-primary);
}

#progressbar li.done:before,
#progressbar li.done:after {
  background: var(--iq-primary);
}

.card-block {
  display: none;
}

.card-block.show {
  display: block;
}

.ckeckout-product-lists {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ckeckout-product-lists:last-child {
  border-bottom: none;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.iq-checkout-icon {
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 22px;
}

.iq-checkout-policy ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* Project management */

.track .iq-card-header {
  border-radius: 15px 15px 0 0;
}

.track .card-body {
  border-radius: 0 0 15px 15px;
}

/* Bootstrap class */

a {
  color: var(--iq-primary);
}

a:hover {
  color: var(--iq-primary);
}

.btn-group-lg > .btn,
.btn-lg {
  border-radius: 20px;
}

.rounded {
  border-radius: 15px !important;
}

.rounded-circle {
  border-radius: 10px !important;
}

.snackbar-container {
  border-radius: 4px !important;
  padding: 14px 20px;
}

.btn {
  border-radius: 10px;
}

#v-pills--d-0 {
  background: transparent;
}

.page-item.active .page-link {
  border-color: var(--iq-primary);
  background: var(--iq-primary);
  color: #fff !important;
}

.page-link {
  color: var(--iq-primary) !important;
}

.wizard-navigation .wizard-progress-with-circle {
  display: none;
}

.meeting .ag-main {
  background: #000 !important;
  border-radius: 15px !important;
}

.meeting.wrapper {
  background: #fff !important;
}

.card-header {
  background-color: #fff !important;
}

.chat-head header {
  border-radius: 0 15px 0 0 !important;
}

.chat-footer {
  border-radius: 0 0 15px 0 !important;
}

.chat-data {
  border-radius: 15px !important;
}

.ag-btn-group {
  border-radius: 0 0 15px 15px !important;
}

.iq-global-search {
  max-height: 300px;
  overflow-y: scroll;
}

.overlay {
  background: rgba(0, 0, 0, 0.6588235294);
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 12;
}

#iq-file-manager {
  font-family: "Roboto", sans-serif;
}

#iq-file-manager .btn-secondary {
  background: var(--iq-primary);
  background: -webkit-gradient(left top, right top, color-stop(0%, var(--iq-primary)), color-stop(100%, var(--iq-primary-light)));
  background: -webkit-gradient(linear, left top, right top, from(var(--iq-primary)), to(var(--iq-primary-light)));
  background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#827af3", endColorstr="#b47af3", GradientType=1);
  border-color: var(--iq-primary);
}

#iq-file-manager .btn-secondary.active {
  background: rgba(130, 122, 243, 0.2);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(130, 122, 243, 0.2)), color-stop(100%, rgba(180, 122, 243, 0.2)));
  background: -webkit-gradient(linear, left top, right top, from(rgba(130, 122, 243, 0.2)), to(rgba(180, 122, 243, 0.2)));
  background: linear-gradient(to right, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  background: var(--iq-primary-light) !important;
}

#iq-file-manager .badge-secondary {
  background: var(--iq-primary);
  background: -webkit-gradient(left top, right top, color-stop(0%, var(--iq-primary)), color-stop(100%, var(--iq-primary-light)));
  background: -webkit-gradient(linear, left top, right top, from(var(--iq-primary)), to(var(--iq-primary-light)));
  background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#827af3", endColorstr="#b47af3", GradientType=1);
  border-color: var(--iq-primary);
}

#iq-file-manager .active-manager {
  background: rgba(130, 122, 243, 0.2);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(130, 122, 243, 0.2)), color-stop(100%, rgba(180, 122, 243, 0.2)));
  background: -webkit-gradient(linear, left top, right top, from(rgba(130, 122, 243, 0.2)), to(rgba(180, 122, 243, 0.2)));
  background: linear-gradient(to right, rgba(130, 122, 243, 0.2) 0%, rgba(180, 122, 243, 0.2) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#827af3", endColorstr="#b47af3", GradientType=1);
  color: var(--iq-primary) !important;
  background-color: rgba(130, 122, 243, 0.2);
}

#iq-file-manager .table-info {
  background-color: rgba(130, 122, 243, 0.2);
  border-color: rgba(130, 122, 243, 0.2);
}

#iq-file-manager .table-info > tr,
#iq-file-manager .table-info > td,
#iq-file-manager .table-info > th {
  background-color: rgba(130, 122, 243, 0.2);
  border-color: rgba(130, 122, 243, 0.2);
}

#iq-file-manager .fm {
  background-color: unset;
  height: 80vh !important;
}

#iq-file-manager .fm .fm-body {
  border-top-color: #cecece;
  border-bottom-color: #cecece;
}

#iq-file-manager .fm-tree {
  border-right-color: #cecece;
}

#iq-file-manager .fm-info-block {
  border-bottom-color: #cecece;
}

.dark .fm {
  background-color: unset;
  height: 80vh !important;
}

.dark .fm-tree .fm-tree-disk {
  background-color: unset;
}

.dark .fm-table thead th {
  background-color: unset;
}

.dark .fm-tree-branch li > p:hover,
.dark .fm-tree-branch li > p.selected {
  background-color: rgba(29, 32, 63, 0.6784313725);
}

.dark #iq-file-manager .fm .fm-body {
  border-top-color: rgb(181, 134, 239);
  border-bottom-color: rgb(181, 134, 239);
}

.dark #iq-file-manager .fm-tree {
  border-right-color: rgb(181, 134, 239);
}

.dark #iq-file-manager .fm-info-block {
  border-bottom-color: rgb(181, 134, 239);
}

.dark #iq-file-manager .table-sm > thead tr:hover,
.dark #iq-file-manager .table-sm > tbody tr:hover,
.dark #iq-file-manager .table-sm > thead th:hover {
  background-color: rgba(29, 32, 63, 0.6784313725);
}

[dir=ltr] .btn i:not(.ri-2x) {
  font-size: 16px;
}

.multiselect .multiselect__select {
  height: 36px;
}

.multiselect__tag {
  overflow: inherit !important;
}

.multiselect .multiselect__tags {
  min-height: 36px;
  border-radius: 5px;
  padding: 5px 40px 5px 8px;
}

.multiselect__placeholder {
  color: #000000 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.filter-btn button {
  height: 36px;
  margin: 22px 0 0;
  width: 100%;
}

/* top-tab-horizontal */

.top-tab-horizontal .iq-top-navbar .iq-sidebar-logo .top-logo {
  display: block;
}

.top-tab-horizontal .iq-sidebar-logo {
  padding: 0;
  display: block;
}

.top-tab-horizontal .content-page {
  padding: 90px 15px 0;
  width: 100%;
  margin-left: 0;
}

.top-tab-horizontal .iq-footer {
  margin-left: 0;
}

.tab-menu-horizontal {
  background: var(--iq-primary) !important;
  margin-top: 75px;
  transition: all 0.45s ease 0s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 12;
}

body.top-tab-horizontal .tab-menu-horizontal.menu-down {
  top: -60px;
  transition: all 0.45s ease 0s;
  opacity: 0;
}

body.top-tab-horizontal .tab-menu-horizontal.menu-up {
  top: 0;
  opacity: 1;
  transition: all 0.45s ease 0s;
}

.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li > ul.iq-submenu {
  display: none;
  position: absolute;
  top: 150%;
  background: var(--iq-white);
  min-width: 260px;
  z-index: 9;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.2);
  transition: all 0.45s ease 0s;
  border-radius: 0 0 8px 8px;
}

.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li:hover > ul.iq-submenu {
  display: block;
  opacity: 1;
  visibility: visible;
  top: 100%;
  transition: all 0.45s ease 0s;
}

.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li > ul.iq-submenu#ui-elements {
  height: 350px;
  overflow-y: scroll;
}

.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li > ul.iq-submenu ul.iq-submenu {
  left: 130%;
  top: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  display: block;
  transition: all 0.45s ease 0s;
  border-radius: 0 8px 8px 8px;
}

.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li > ul.iq-submenu li:hover ul.iq-submenu {
  left: 100%;
  opacity: 1;
  visibility: visible;
  transition: all 0.45s ease 0s;
}

.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li {
  padding: 10px 0;
  margin: 0;
}

.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li.active {
  margin: 0;
}

.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li.active .iq-submenu li.active > a {
  background-color: transparent;
}

.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li > a {
  background: transparent;
  border-radius: 8px;
  padding: 10px 15px;
  color: var(--iq-white);
  transition: all 0.5s ease-out 0s;
}

.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li a[aria-expanded=true] {
  background: transparent;
}

.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li:hover > a,
.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li > a.active {
  color: var(--iq-primary-dark);
  background: var(--iq-white);
  transition: all 0.5s ease-out 0s;
}

body.top-tab-horizontal .iq-top-navbar .top-menu {
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  min-height: 73px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: inline-block;
  z-index: 99;
  background: var(--iq-white);
  -webkit-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  -moz-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
}

body.top-tab-horizontal .iq-top-navbar {
  position: static;
  box-shadow: none;
}

.icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: contain;
}

.icon.icon-customers {
  background-image: url("../images/icon-customer.png");
}

.icon.icon-cancellation {
  background-image: url("../images/icon-cancellation.png");
}

.icon.icon-forms {
  background-image: url("../images/icon-anfragen.png");
}

.icon.icon-status {
  background-image: url("../images/icon-status.png");
}

.icon.icon-orders {
  background-image: url("../images/icon-orders.png");
}

.icon.icon-gewinnspiel {
  background-image: url("../images/icon-gewinnspiel.png");
}

.icon.icon-mails {
  background-image: url("../images/icon-mails.png");
}

.icon.icon-miles {
  background-image: url("../images/icon-miles.png");
}

.icon.icon-newsletter {
  background-image: url("../images/icon-newsletter.png");
}

/* css for newsletter page top margin */

.margin-top-25 {
  margin-top: 25px;
}

