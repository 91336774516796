/**
 * Project:           FCNM Dashboard
 * Version:           1.0.0
 * Author:            Umair Ishtiaq
 * Author URI:        https://umairishtiaq.com
 */

@import "bootstrap";
@import "../../node_modules/animate.css/animate.min.css";
@import "../../node_modules/node-snackbar/src/sass/snackbar";
@import "../fonts/remixicon.css";
@import "../fonts/fontawesome.css";

:root {
    --iq-card-shadow-1:rgba(226, 190, 253,.15);
    --iq-card-shadow-2:rgba(226, 190, 253,.15);
    --iq-primary-shadow-1:rgba(78,31,249,.5);
    --iq-primary-shadow-2:rgba(78,31,249,.3);
    --iq-dropdown-shadow:rgba(0, 0, 0,.05);

    --iq-title-text: #555770;
    --iq-body-text: #555770;

    --iq-body-bg : #f8f7fd;
    --iq-card-bg: #fff;

    --iq-dropdown-bg: #fff;
    --iq-dropdown-border: #fff;

    --iq-primary: #325D85;
    --iq-primary-light: #478BCA;
    --iq-primary-dark: #0A133B;
    --iq-secondary: #EBB834;

    --iq-success: #198754;
    --iq-warning: #FAD02E;
    --iq-danger: #DC3545;
    --iq-info: #00CFDE;

    --iq-dark: #999999;
    --iq-light: #FDFAFF;
    --iq-white: #ffffff;
    --iq-black: #000000;

    --iq-border: #4180AE;
    --iq-bg: #F3F3F3;
    --iq-bg-light: #F9F9F9;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import "typography";
@import "styles";

.ag-theme-balham .ag-paging-panel {
    font-weight: bold;
}

.iq-global-search>ul>li a, .slick-arrow, .breadcrumb-item>a{
    color: var(--iq-primary);
    &:hover{
        color: var(--iq-primary);
    }
}

.user-actions {
    a, button {
        padding: 0;
        vertical-align: middle;
    }
}

.iq-card-header-toolbar {
    a, button {
        margin-left: 10px;
    }
}

.flag {
    margin: -7px 0 0 -16px;
}
.flag-w-text {
    span {
        vertical-align: middle;
    }
    .flag {
        margin: 0 -22px;
    }
}

.form-control:disabled,
.form-control[readonly] {
    background: #e9ecef;
    &.flatpickr-input {
        background: none;
    }
}
[dir=ltr] textarea.form-control {
    min-height: 100px;
    line-height: 1.8;
}
select.form-control {
    padding-left: .5rem;
    padding-right: .5rem;
}
select.form-control[multiple] {
    min-height: 100px !important;
}

.batch-form {
    select.custom-select {
        border-radius: 10px;
    }
    .btn {
        padding: 4.3px 10px;
    }
}

.d-flex .badge {
    line-height: 1.8;
}

select.form-control[multiple],
select.form-control[size] {
    min-height: 36px;
}

.custom-file-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 80px;
    overflow: hidden;
}

.btn-outline-primary:hover {
    color: #ffffff !important;
}

.custom-radio {
    padding-top: 5px;
    padding-bottom: 5px;
}

.custom-switch {
    padding-top: 5px;
}

.ag-row .ag-cell {
    display: flex;
    align-items: center;
}

// PDF Generator Preview

.modal-body {
    .pdf-preview {
        padding: 30px;
        font-size: 10px;
        line-height: 16px;
        font-family: Verdana, sans-serif;
        color: #000000;
        width: 794px;
        table {
            width: 100%;
            margin: 0;
            thead {
                background: #cccccc;
                th {
                    border-width: 1px;
                }
            }
            tbody {
                td {
                    vertical-align: top;
                }
            }
            strong {
                font-size: 12px;
            }
            p {
                margin: 0;
            }
            &:not(.text-center) {
                th:last-child,
                td:last-child {
                    text-align: right;
                }
            }
        }
        ul {
            margin: 0;
            padding: 0;
        }
        p, li, td {
            font-size: 10px;
            line-height: 16px;
            margin: 0;
        }
        p {
            margin: 0 0 1rem;
        }
        a {
            color: inherit;
        }
    }
}

#pdfPreview {
    .modal-body {
        padding: 0;
    }
}

.trumbowyg-editor {
    table {
        td {
            padding: 0 !important;
            border: 0 !important;
        }
    }
}

.multiselect {
    .multiselect__select {
        height: 43px;
    }
    .multiselect__tags {
        min-height: 45px;
        border-radius: 10px;
        .multiselect__input,
        .multiselect__single {
            font-size: 14px;
            padding: 0;
            line-height: 28px;
            margin: 0;
        }
    }
    .multiselect__content {
        max-width: 100%;
        .multiselect__option {
            padding: 8px 6px;
            min-height: 24px;
            line-height: 12px;
            font-size: 12px;
        }
    }
    &.is-invalid {
        .multiselect__tags {
            border-color: red;
        }
    }
}

.height-400 {
    height: calc(100vh - 300px) !important;
}
.ag-theme-balham .ag-cell {
    white-space: normal !important;
    line-height: 18px !important;
}

.dropzone {
    .dz-message {
        margin: 9rem 0 !important;
        font-size: 1rem;
    }
}

.iq-email-listbox .iq-email-sender-list > li.iq-unread {
    background: #f0f0f0;
}

.bg-danger-light {
    background: rgba(#fd6256, 0.5) !important;
}
.bg-danger-dark {
    background: rgba(#fd6256, 1) !important;
}
.bg-info-light {
    background-color: #e6e6e6  !important;
}

@import "custom";

